.header {
  flex: 0 0 auto;
  display: flex;
  background: #F5F7F9 0 0 no-repeat padding-box;
  padding: 15px 45px;
  border-radius: 14px 14px 0 0;
  box-shadow: 0px 19px 36px #00000029;
  position: relative;
  z-index: 2;

  .nameInfo {
    flex: 1 1 auto;

    .fioWrap {
      display: flex;
      align-items: center;
      line-height: normal;
      margin-bottom: 8px;

      h2 {
        margin: 0;
      }

      span {
        font: normal normal normal 16px/32px Roboto;
        letter-spacing: 0px;
        color: #C1C8D8;
        margin: 0 5px;
        line-height: 20px;
      }
    }

    margin-right: 30px;
  }

  h2 {
    font: normal normal bold 16px/32px Roboto;
    letter-spacing: 0;
    color: #43425D;
    margin: 0 0 8px;
    line-height: normal;
  }

  p {
    font: normal normal normal 12px/32px Roboto;
    letter-spacing: 0px;
    color: #43425D;
    margin: 0 0 6px;
    line-height: normal;
  }

  span {
    font: normal normal normal 12px/32px Roboto;
    letter-spacing: 0px;
    color: #C1C8D8;
    line-height: normal;
  }
}