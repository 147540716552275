.field {
  display: flex;
  :first-child {
    flex: 0 0 154px;
    font-weight: bold;
  }
  &:not(:last-child) {
    margin-bottom: 8px;
  }
}
.noInfo {
  color: #bbbbbb;
}