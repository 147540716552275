.locomotives_ul {
  list-style: none;
  padding: 0;
  display: inline-flex;
  width: 100%;
  justify-content: center;

  li {
    width: 650px;
    display: flex;
    justify-content: space-between;

    &.date__picker {
      display: flex;
    }

    // &:not(:last-child) {
    //   margin-right: 10px;
    // }
  }
}

.locomotives_label {
  margin-bottom: 10px !important;
}

.added_loco_in_orders {
  list-style: none;
  display: flex;
}

.locOrders_container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.added_loco_li {
  margin-right: 20px;
}