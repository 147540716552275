.newTaskDate {
  div {
    label {
      color: rgb(118, 118, 118) !important;
    }
  }

  > :not(:last-child) {
    margin-right: 20px;
  }

}

.templateHeader {
  color: rgba(128, 128, 128, 0.85) !important;
  display: flex !important;
  flex-direction: row !important;
  justify-content: space-between !important;
  align-items: center !important;
  padding-right: 25% !important;
  margin: 5px 0 !important;
  padding-top: 0 !important;

  div:first-child {
    margin: 0 !important;
  }

  .cancelEdit {
    font-size: 10px !important;
    font-weight: 400 !important;
    color: #ea2525;
  }
}

.nsiContent {
  display: flex !important;
  flex-display: row !important;
  align-items: flex-start;
  width: 100%;
  height: 100%;
  background: #f5f7f9 !important;

  .templates {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;

    .addTemplate {
      color: #0d71bb !important;
      box-shadow: none !important;
      padding-left: 0;
      padding-top: 0;

      &:hover {
        background: transparent !important;
      }
    }
  }

  .tempItem {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    input {
      max-width: 150px;
      padding: 0;
    }

    i {
      margin: 0 10px 7px 0px;
      cursor: pointer;
    }
  }

  .tempList {
    font-weight: 550 !important;
    font-size: 15px;
    font-family: system-ui !important;
  }

  .newTempInput {
    max-width: 155px !important;

    input {
      margin-bottom: 5px;
    }
  }

  .templateItem {
    display: flex;
    flex-direction: column;
    width: 100%;
    justify-content: center;
    align-items: center;
    padding-left: 5% !important;
    margin-top: 5px;

    .addStatus {
      color: #0d71bb !important;
      box-shadow: none !important;
      padding-left: 0;
      padding-top: 0;
      margin: 10px 0 10px 0;

      &:hover {
        background: transparent !important;
      }
    }

    h4 {
      font-size: 11px;
      text-transform: uppercase;
      color: rgba(128, 128, 128, 0.85);
      font-weight: 600;
    }

    .addStatusActionBox {
      display: flex;
      flex-direction: row;
      justify-content: flex-end;
      align-items: center;

      button:last-child {
        padding-right: 5px;
      }

      .addStatusAction {
        color: #0d71bb !important;
        box-shadow: none !important;
        padding-left: 0;
        padding-top: 0;
        margin: 5px 0 5px 0;

        &:hover {
          background: transparent !important;
        }
      }
    }

    > div {
      width: 100%;
      display: flex;
      flex-direction: column;


      .statusCatList {
        width: 100%;
        display: flex;
        flex-direction: row;
        align-items: center;
        border-radius: 5px;
        border: 1px solid rgba(0, 0, 0, 0.31);
        margin-bottom: 5px;
        background: white;

        .actionsIcons {
          display: flex;
          align-items: center;
          flex-direction: row;
          margin: 0 15px 6px 2px;
          justify-self: flex-start;
        }

        .editIcons {
          display: none;
        }

        &:hover {
          .editIcons {
            display: flex;
            align-items: center;
            flex-direction: row;
            margin-bottom: 3px;

            > i {
              cursor: pointer;
            }
          }
        }

        .actionIcons {
          display: flex;
          align-items: center;
          flex-direction: row;
          margin-bottom: 3px;

          > i {
            cursor: pointer;
          }
        }

        .dropAddStat {
          border: none !important;
        }

        .colorState {
          border-radius: 3px;
          width: 10px;
          height: 10px;
          margin-left: 10px;
        }

        span {
          margin-left: 10px;
          text-transform: uppercase;
          font-family: monospace;
          line-height: 24px;
        }
      }
    }
  }
}