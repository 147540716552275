.footer {
  flex: 0 0 auto;
  display: flex;
  background: #F5F7F9 0 0 no-repeat padding-box;
  padding: 15px 45px;
  border-radius: 0 0 14px 14px;
  box-shadow: 0px 19px 36px #00000029;
  position: relative;
  z-index: 2;

  .mark {
    flex: 1 1 auto;
    display: flex;
    flex-direction: row;
    align-items: center;
    // justify-content: space-between;

    button {
      margin-right: 10px
    }

    p {
      padding-top: 10px;
      font: normal normal normal 12px/32px Roboto;
      letter-spacing: 0px;
      color: #43425D;
      margin: 0 0 6px;
      line-height: normal;
    }
  }
}