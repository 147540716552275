.sidebarBox {
  height: 100%;
  overflow-x: hidden;
  width: 100%;
  background: #f5f7f9;
  display: flex;
  flex-direction: row;
  transform: none !important;

  .sidebarList {
    background-color: white;
    flex: 0 0 auto;
    overflow: auto;

    &::-webkit-scrollbar-thumb {
      background-color: transparent;
      border-radius: 10px;
      height: 50px;
    }

    &::-webkit-scrollbar {
      width: 1px;
      background-color: transparent;
    }

    &::-webkit-scrollbar-track {
      box-shadow: transparent;
      background-color: transparent;
      height: 50px;
    }
  }

  .sidebarLoader {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .sidebarHeaderBtns {
    display: flex;
    justify-content: flex-end;
    flex-direction: row;
    align-items: center;
    padding-right: 10px;
  }

  .editIconSidebar {
    font-size: 18px !important;
    z-index: 1000;
  }

  .editDiv {
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    position: absolute;
  }

  .addInput {
    display: flex;
    flex-direction: row;
    //align-items: center;
    //justify-content: center;
    width: 90%;
    height: 100%;
  }

  .loadingAria {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 100px;
    flex: 1 1 auto;
    color: rgba(0, 0, 0, 0.64);
    font-size: 30px;

    > div {
      color: rgba(0, 0, 0, 0.64);
    }
  }

  .taskboardArea {
    display: flex;
    flex-direction: column;
    width: calc(100% - 240px);
    margin-right: 10px;
  }

  .taskBoardHeader {
    flex: 0 0 auto;
    position: sticky;
    top: 0;
    background: #f5f7f9;
    height: 55px;
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-right: 20px;
    z-index: 1;

    .sortDropAria {
      padding-left: 0;
    }

    > span {
      font: normal normal medium 17px/20px Roboto;
      color: #6e7076;
      padding-left: 25px;
      font-weight: 500;

      .sortDrop {
        font: normal normal 300 15px/18px Roboto;

        div {
          font-weight: 400 !important;
        }
      }

      .groupDrop {
        font: normal normal 300 15px/18px Roboto;

        div {
          font-weight: 400 !important;
        }
      }

      .subtaskDrop {
        font: normal normal 300 15px/18px Roboto;

        div {
          font-weight: 400 !important;
        }
      }
    }

    .listMode {
      display: flex;
      flex-direction: row;
      align-items: center;
      min-width: 285px;

      .modeTabs {
        background-color: #f5f7f9;
        width: 100%;

        > ul {
          background-color: transparent;
          border: none;

          > li {
            font-weight: 500;
          }

        }
      }

      > span {
        color: #6e7076;
        padding-left: 25px;
        font-weight: 500;

        > button {
          margin-left: 5px;
        }
      }

    }

    .boardName {
      font-weight: bold;
      font-size: 18px;
      color: #000000DE;
      padding-left: 20px;
    }
  }

}

:global {
  .MuiPaper-root {
    background-color: transparent !important;
  }

  .MuiDialog-paper {
    background-color: #fff !important;
  }


}


.squadIcon {
  width: 10px;
  height: 10px;
  margin-right: 5px;
}

.circleIcon {
  border-radius: 50%;
  width: 10px;
  height: 10px;
  margin-right: 5px;
}