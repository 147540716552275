.column {
  flex: 0 0 auto;
  display: flex;
  flex-direction: column;
  min-width: 162px;

  &:nth-child(2) {
    padding: 0 75px;
    flex: 1 1 auto;
  }

  .itemColumn {
    margin-bottom: 35px;

    &:last-child {
      margin-bottom: 0;
    }

    .rowItem {
      margin-bottom: 12px;
    }

    .title {
      margin-bottom: 16px;
      font: normal normal bold 16px/32px Roboto;
      letter-spacing: 0;
      line-height: 21px;
      color: #43425D;
      position: relative;

      &:after {
        content: "";
        position: absolute;
        bottom: -2px;
        left: 0;
        width: 162px;
        height: 1px;
        background-color: #C1C8D8;
      }
    }

    h4 {
      font: normal normal normal 12px/16px Roboto;
      line-height: 16px;
      color: #43425D;
      margin: 0 0 6px;
    }

    p {
      color: #C1C8D8;
      margin: 0;
      line-height: 12px;
      font-size: 12px;
    }
  }
}