.organInspecGrid {
  width: 100%;

  .nameSpace {
    font-weight: bold;
    font-size: 20px;
  }

  .workSegment {
    //min-height: 30vh;
    //max-height: 30vh;
  }

  .workSegmentChart {
    min-height: 50vh;
    max-height: 50vh;
    max-width: 74vw;
    margin-top: 10px;

    > div {
      overflow: auto !important;
      // max-height: 45vh;
    }
  }

  .dropFilter {
    label {
      color: #0f0f10;
      background-color: #f6f6f6;
    }
  }

  .genInfoGrid {
    margin: 10px;

    .infoHeader {
      font-size: 15px;
      font-weight: bold;
    }

    .planKOheader {
      font-size: 14px;
      font-weight: normal;
      margin-top: 0px;
      margin-bottom: 0px;
      padding-bottom: 0px !important;
    }

    .rowPlanKO {
      padding: 0px;

      > :not(:last-child) {
        margin-right: 20px;
      }
    }

    .infoRowGrid {
      div {
        padding-bottom: 8px;
      }

      th {
        background-color: rgba(0, 0, 0, 0.09);
      }
    }
  }

  .addWorkGrid {
    margin: 10px;

    .addHeader {
      font-size: 15px;
      font-weight: bold;
    }
  }


  :global {
    .work-column {
      min-width: 80%
    }

    .filter-column {
      max-width: 20%;
    }
  }
}

.addModal {
  label {
    color: black !important;
  }
}

.TextField {
  label {
    font-weight: 700;
    font-size: 15px !important;
  }

  span {
    font-size: 16px;
  }
}

.chooseFirm {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 600;
  font-size: 20px;
}

.noData {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 20%;
}