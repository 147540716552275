.fleet-stats  {
	// support ie11
	display: -ms-grid;
	display: grid;
	-ms-grid-columns: 1fr 1fr 1fr;
	grid-template-columns: 1fr;
	align-items: center;
	font-family: "Lucida Grande", "Lucida Sans Unicode", Arial, Helvetica, sans-serif;

	>:nth-child(1) {
		-ms-grid-row: 1;
		-ms-grid-column: 1;
	}
	>:nth-child(2) {
		-ms-grid-row: 1;
		-ms-grid-column: 2;
	}
	>:nth-child(3) {
		-ms-grid-row: 1;
		-ms-grid-column: 3;
	}
	>:nth-child(4) {
		-ms-grid-row: 2;
		-ms-grid-column: 1;
	}
	>:nth-child(5) {
		-ms-grid-row: 2;
		-ms-grid-column: 2;
	}
	>:nth-child(6) {
		-ms-grid-row: 2;
		-ms-grid-column: 3;
	}
	>:nth-child(7) {
		-ms-grid-row: 3;
		-ms-grid-column: 1;
	}
	>:nth-child(8) {
		-ms-grid-row: 3;
		-ms-grid-column: 2;
	}
	>:nth-child(9) {
		-ms-grid-row: 3;
		-ms-grid-column: 3;
	}

	.progress-descr {
		text-align: center;
		font-size: 12px;
		font-weight: normal;
		margin-bottom: 10px;
	}

	.progress-title {
		padding-top: 2px;
		margin-bottom: 10px;
		font-family: "Lucida Grande", "Lucida Sans Unicode", Arial, Helvetica, sans-serif;
		text-align: center;
		font-size: 12px;
		padding-bottom: 8px;
		font-weight: normal;
		border-bottom: 3px solid transparent;
	}

	.cursor-pointer {
		cursor: pointer;
	}

	.selected-service {
		border-bottom: 3px solid #aaa;
		font-weight: bold;
	}

	.progress-bar-with-popup:hover {
		cursor: pointer;
	}
	
	.half-circle-container {
		height: 100px;
		.half-circle-progress-bar {
			margin: 0 auto;
		}
		.highcharts-container {
			margin: 0 auto;
			height: 80px;
		}
	}
	.progress-bar-container {
		.progress-bar-with-popup {
			margin: 0 auto;
		}
	}
}

