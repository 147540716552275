.accordion {
  :global(.title):not(:global(.open)) {
    color: #7b7b7b !important;
  }
  :global(.title):global(.open) {
    color: black;
  }
  :global(.title):not(:global(.lastTitle)) {
    border-bottom: 1px solid #d4d4d5;
  }
  :global(.open) {
    border-bottom: 1px solid transparent !important;
  }
}