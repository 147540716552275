.chartAria {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  .legend {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    padding-top: 5px;

    .legendColor {
      border-radius: 5px;
      width: 10px;
      height: 10px;
    }

    span {
      font-size: 12px;
      color: #333333;
      padding: 0px 10px 0px 5px;
      font-family: monospace;
    }
  }

  .columnAria {
    padding: 60px 10px 20px 10px;
    min-height: 230px;
    display: flex;
    align-items: flex-end;
    width: calc(100% - 200px);
    justify-content: space-between;


    .singleCol {
      position: relative;
      display: flex;
      flex-direction: column;
      cursor: pointer;
      &:nth-last-child(-n+5) .hoverCard {
        left: initial;
        right: -100px;
      }
      .hoverCard {
        background-color: white;
        visibility: hidden;
        position: absolute;
        left: -100px;
        bottom: 70px;
        box-shadow: rgb(174, 174, 174) 0px 0px 10px;
        color: rgb(89, 89, 89);
        border-radius: 8px;
        padding: 3px;
        z-index: 10;
        font-size: 12px;
        //font-weight: 500;

        > div {
          width: 360px;
        }
        //> div {
        //  display: flex;
        //  flex-direction: row;
        //  align-items: center;
        //  justify-content: flex-start;
        //
        //  > span {
        //    padding-left: 5px;
        //  }
        //
        //  > div {
        //    border-radius: 5px;
        //    width: 10px;
        //    height: 10px;
        //  }
        //}
      }

      &:hover {
        .hoverCard {
          visibility: visible;
        }

        .lastValue {
          border: 1px solid black;
        }

        .currentValue {
          border: 1px solid black;
        }
      }

      .column {
        width: 7px;
        height: 150px;
        padding-top: 15px;
        display: flex;
        flex-direction: column-reverse;

        .lastValue {
          background-color: #757575;
          border-radius: 2px;
        }

        .devider {
          background-color: #FFFFFF;
          width: 100%;
          height: 5%;
        }

        .currentValue {
          background-color: #709AC2;
          width: 100%;
          border-radius: 2px;
        }
      }

      .title {
        position: relative;
        display: inline-flex;
        color: #33333399;
        font-size: 10px;
        width: 10px;
        top: 4em;
        white-space: nowrap;
        transform: rotate(-90deg);
      }
    }

    //:last-child {
    //  .hoverCard {
    //    left: -190px;
    //    top: 10px;
    //  }
    //}
  }
}