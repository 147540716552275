.app.audit-control.event-details-modal {
  .footer {
    display: flex;
    justify-content: flex-end;
  }

  .general-info{
    display: flex;
    justify-content: flex-start;

    > *:not(:last-child) {
      margin-right: 20px;
    }
    > * {
      padding: 0;
    }
  }
}