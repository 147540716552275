:global {
  .MuiAutocomplete-popper {
    background-color: #ffffff;
  }

  #buttonToTop {
    position: fixed;
    right: 1%;
    bottom: 10%;
    opacity: 0;
    transition: all 0.3s;

    &.active {
      opacity: 1;
    }
  }

  #top {
    position: absolute;
    top: -15px;
  }
  .ant-picker-dropdown{
    z-index: 1000000000!important;
  }
}


.container {
  overflow-y: auto;
  height: 100%;
  width: 100%;
  display: flex;
  align-items: flex-start;
  padding: 15px;
}

.menuCategory {
  width: 170px;
  background-color: #ffffff;
  margin-top: 20px !important;
  padding: 10px !important;

  .activeMenuItem {
    color: #1678c2;
  }
}

.containerNews {
  flex: 1 1 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  margin-right: 15px;

  .attachedFile {
    position: relative;
    z-index: 1;
    cursor: pointer;

    &:hover {
      text-decoration: underline;
    }
  }
}

.countComments {
  position: relative;
  z-index: 1;
  cursor: pointer;

  &:hover {
    text-decoration: underline;
  }

  span {
    font-weight: bold;
  }
}

.actions {
  transition: all 0.3s;
  flex: 0 0 auto;
  display: flex;
  flex-direction: column;
  margin-bottom: 30px;
  align-items: flex-end;
  position: sticky;
  top: 0;
}

.linkBody {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
}

.card {
  position: relative;
  width: 100%;
  box-shadow: 0 16px 17px -12.125px rgba(0, 0, 0, 0.3) !important;
  margin-bottom: 50px;
  background-color: #ffffff !important;
  display: flex;
  min-height: 350px;

  &:hover {
    box-shadow: none !important;
  }

  .contentBody {
    max-height: 150px;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 7;
    -webkit-box-orient: vertical;
    text-overflow: ellipsis;
    cursor: pointer;
  }

  .category {
    margin-bottom: 5px;
    display: inline-block;
    text-decoration: underline;
    cursor: pointer;
    position: relative;
    z-index: 1;
  }

  .media {
    flex: 0 0 30%;
    min-height: 350px;
    cursor: pointer;
  }

  .attachedFile {
    margin: 20px 0 5px;
    font-weight: bold;

    > span {
      font-weight: normal;
    }
  }

  .cardContent {
    flex: 1 1 auto;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    h6 {
      cursor: pointer;
      transition: all 0.3s;
      display: inline-block;
      position: relative;
      z-index: 1;

      &:hover {
        color: #2185d0;
        text-decoration: underline;
      }
    }
  }

  .description {
    margin-top: 20px;
    display: flex;
    align-items: flex-end;
    justify-content: space-between;

    .item {
      display: flex;
      align-items: flex-end;

      svg {
        margin-right: 5px;
      }
    }
  }
}

.modalNews {
  .itemField {
    margin-bottom: 20px;
    width: 100%;
    display: flex;
    align-items: center;

    > div {
      flex: 1 1 auto;

    }

    > span {
      margin-right: 10px;
    }
  }

  .itemFileField {
    margin-bottom: 20px;
    width: 100%;
    display: flex;
    align-items: center;

    > span {
      margin-right: 10px;
    }
  }

  .dropPhotoClass {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    > div {
      justify-content: center;
    }
  }

  .dropFileClass {
    min-height: 100px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    > div {
      justify-content: center;

      > svg {
        display: none;
      }
    }
  }
}


.newsDetailsWrap {
  position: relative;
  width: 100%;
  min-height: calc(100% - 15px);

  background-color: transparent;

  .titleDetailsNews {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 10px;

    h6 {
      margin: 0;
    }
  }

  .backButton {
    position: absolute !important;
    top: -15px;
    left: -15px;
    height: calc(100% + 30px);
    padding: 0 20px;


    > span:first-child {
      align-self: flex-start;
      position: sticky;
      top: 21px;
    }
  }

  .card {
    max-width: 800px;
    cursor: default;
    box-shadow: none !important;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    background-color: transparent !important;

    > div {
      background-color: #ffffff;
    }
  }

  .category {
    cursor: default;
  }

  .media {
    padding-top: 300px;
    cursor: default;
  }

  .description {
    margin-bottom: 20px;
  }

  .skeletonComment {
    display: flex;
    align-items: center;

    > div:last-child {
      margin-left: 20px;
      flex: 1 1 auto;
    }
  }

  .mainComment {
    width: 100%;
    display: flex;
    align-items: center;
  }
}
.commentTitle{
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.editActions{
  display: flex;
  align-items: center;
  margin-top: 10px;
  width: 100%;
  justify-content: flex-end;
}
.head{
  display: flex;
  align-items: center;
  svg{
    width: 30px;
    height: 30px;
    margin-right: 10px;
  }
}
.toDay{
  position: absolute!important;
  bottom: 5px;
}