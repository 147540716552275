/* pie chart tip on hover*/
.leaflet-pane.leaflet-marker-pane .leaflet-marker-icon.leaflet-div-icon.leaflet-zoom-animated.leaflet-interactive {
    width: auto !important;
    height: auto !important;

    .legend .legend-box {
        width: 50px;
    }
    .legend-values > div {
        white-space: nowrap;
    }
}
.leaflet-tooltip.diesel-locomotive
, .leaflet-tooltip.electric-locomotive-direct
, .leaflet-tooltip.electric-locomotive-alternating
, .leaflet-tooltip.nr
, .leaflet-tooltip.nr-repair-wait
, .leaflet-tooltip.nr-avg-pereprost {
    background-color: rgba(255, 255, 255, 0);
    border-width: 0px;
    padding: 0px;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;
    font-weight: bold;
    z-index: 0 !important;
}


.leaflet-tooltip.diesel-locomotive, .leaflet-tooltip.nr-avg-pereprost {
    margin: 15px 0px 0px 10px;
}

.leaflet-tooltip.electric-locomotive-direct, .leaflet-tooltip.nr-repair-wait {
    margin: -15px 0px 0px 10px;
}

.leaflet-tooltip.electric-locomotive-alternating, .leaflet-tooltip.nr {
    margin: 1px 0px 0 -17px;
}

/* pie chart tip on hover*/
.segment.polygon-ratings .canvas .leaflet-pane.leaflet-map-pane .leaflet-marker-icon.leaflet-div-icon.leaflet-zoom-animated.leaflet-interactive {
    width: 180px !important;
    height: 85px !important;
}

.segment.polygon-ratings .canvas .leaflet-pane.leaflet-map-pane .leaflet-marker-icon.leaflet-div-icon.leaflet-zoom-animated.leaflet-interactive .legend .legend-values .key {
    display: none;
}

/* pie chart tip on hover*/
.segment.polygon-ratings .canvas .leaflet-pane.leaflet-map-pane .leaflet-marker-icon.leaflet-div-icon.leaflet-zoom-animated.leaflet-interactive .legend .legend-values .key
, .segment.polygon-ratings .canvas .leaflet-pane.leaflet-map-pane .leaflet-marker-icon.leaflet-div-icon.leaflet-zoom-animated.leaflet-interactive .legend .legend-values .value {
    font-size: 12px !important;
    line-height: 130%;
    text-align: left;
    margin: 0 6px;
    /*margin: 0px !important;*/

}

.chart-on-leaflet {
    width: calc(50vw - 55px);
}