// text color #9f3a38
// border color #e0b4b4
// background #fff6f6

.attachFiles {
  display: block;

  &:global(.ui.buttons) {
    margin-top: 10px;
  }


}

.errorButton {
  &:global(.ui.buttons) {
    border-color: #e0b4b4 !important;
  }
  &:global(.ui.basic.buttons .button) {
    color: #9f3a38 !important;
  }
}