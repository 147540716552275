.info {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 10px 0 20px 0;

  .photo {
    position: relative;
    width: 90px;
    flex: 0 0 auto;
    margin-left: 15px;

    img {
      background-color: #ffffff;
      position: absolute;
      width: 100%;
      top: -48px;
      left: 0;
      height: 90px;
      border-radius: 50%;
      box-shadow: 0 3px 6px rgba(0, 0, 0, 0.42);
      display: flex;
      align-items: center;
      justify-content: center;
    }

    div {
      background-color: #ffffff;
      position: absolute;
      width: 100%;
      top: -48px;
      left: 0;
      height: 90px;
      border-radius: 50%;
      box-shadow: 0 3px 6px #00000029;
      display: flex;
      align-items: center;
      justify-content: center;
      color: #C1C8D8;
    }
  }

  .desc {
    div {
      font-size: 16px;

      span {
        font-weight: bold;
        margin-right: 5px;
        color: #43425D;
      }
    }

  }

}
