.app.add-event-for-inconsistencies {
  &.ui.modal {
    color: black ;
  }

  .container {
    display: flex;
  }
  .left-container {
    flex: 1;
    padding-right: 20px;
  }
  .right-container {
    flex: 1;
    padding-left: 20px;
  }
  .buttons {
    text-align: right;
  }
}