.subtasksAria {
  height: 100%;
  overflow: auto;
  position: relative;

  .addSub {
    padding: 10px 10px 0;
    display: flex;
    flex-direction: row;
    align-items: center;

    .cancle {
      margin-left: 10px;

      &:hover {
        cursor: pointer;
        font-weight: 600;
      }
    }

    .addSubDone {
      margin-left: 10px;
      color: #1890ff;

      &:hover {
        cursor: pointer;
        font-weight: 600;
      }
    }

    .addSubDis {
      margin-left: 10px;
      color: rgba(128, 128, 128, 0.66);

      &:hover {
        cursor: not-allowed;
      }
    }
  }

  .subtasksList {
    width: 100%;
    padding: 10px 10px 30px 10px;

    > p {
      margin-bottom: 20px;
    }

  }

  .emptySubtasks {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;


    > div {
      display: flex;
      align-items: center;
      padding: 3px;

      :first-child {
        margin-right: 3px;
      }

      &:hover {
        cursor: pointer;
        border: 1px solid rgba(128, 128, 128, 0.34);
        border-radius: 5px;
        background-color: rgba(220, 220, 220, 0.24);
      }
    }

  }
}

.drugLi {
  width: 100%;
  padding: 5px 10px;
  list-style-type: none;

  .drugContainer {
    width: 100%;
    display: flex;
    flex-direction: row;

    > i {
      margin: 0 10px 5px 10px;
    }

    > p {
      width: 100%;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;

      .editSubInput {
        width: 100%;
        padding-right: 15px;

        > input {
          width: 100%;
        }
      }

      .updateInfo {
        .subsAction {
          font-size: 12px;
          color: rgba(0, 0, 0, 0.45);
        }

        .subtuskUpdater {
          white-space: pre;
          font-size: 12px;
          color: rgba(0, 0, 0, 0.45);
        }
      }
    }
  }
}