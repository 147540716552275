.app.dropdown-field {
  .ui.dropdown {
    padding-left: 14px;
    padding-top: 9.5px;
    padding-bottom: 9.5px;
/*    &.active {
      background: transparent;
      border-top: 1px solid transparent !important;
    }*/
    .text {
      line-height: 17px;
    }
  }
  .ui.multiple.search.dropdown > input.search {
    margin: 0;
  }
}