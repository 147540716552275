.analyticalAria {
  width: 100%;
  height: 100%;
  margin: 0 auto;
  overflow: hidden;
  flex-wrap: nowrap;
  display: flex;
  flex-direction: row;

  .chartPart {
    width: 100%;
    display: grid;
    grid-gap: 20px;
    margin: 20px;

    > div {
      background-color: #FFFFFF;
      border-radius: 10px;
      padding: 15px;
      box-shadow: 0px 19px 36px #00000029;

      .loader {
        height: 100%;
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }

    .itemOne {
      position: relative;
      grid-column: 1;
      grid-row: 1;

      > h3 {
        border-bottom: 1px solid rgba(0, 0, 0, 0.15);
        font-weight: 600;
        font-size: 20px;
      }
    }

    .itemTwo {
      grid-column: 2;
      grid-row: 1;

      > h3 {
        border-bottom: 1px solid rgba(0, 0, 0, 0.15);
        font-weight: 600;
        font-size: 20px;
      }
    }

    .itemThree {
      min-height: 280px;
      grid-column: 1 / span 2;
      grid-row: 2;

      > h3 {
        border-bottom: 1px solid rgba(0, 0, 0, 0.15);
        font-weight: 600;
        font-size: 20px;
      }
    }
  }

  .listPart {
    width: 100%;
    height: auto;
    margin: 30px;
  }
}