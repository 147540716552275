.taskList {
  width: 100%;
  height: 100%;
  overflow: auto !important;
  flex-wrap: nowrap !important;
  position: relative;

  .skeletonItem {
    padding: 0 5%;
  }

  .loadingAria {
    z-index: 3;
    position: fixed;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgba(3, 3, 3, 0.1);
    color: rgba(0, 0, 0, 0.64);
    font-size: 30px;
    border-radius: 4px;

    > div {
      color: rgba(0, 0, 0, 0.64);
    }
  }

  .taskTitle {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;

    > a {
      color: #303030;

      &:hover {
        text-decoration: underline;
      }
    }

    .statusDiv {
      padding: 1px 4px;
      border-radius: 5px;
      font-size: 12px;
      margin-left: 5px;
      color: white;
    }
  }

  a {
    .itemCnt {
      margin-left: 5px;
      padding: 3px 5px;
      border-radius: 50%;
    }
  }


  .itemRightContent {
    display: flex;
    align-items: flex-start;
    justify-content: flex-end;
    max-width: 100%;

    .itemComments {
      display: flex;
      flex-direction: row;
      align-items: center;

      > svg {
        margin-left: 5px;
      }

      .subsCnt {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        color: #a1a1a4;

        > svg {
          margin: 0 2px 0 5px;
        }
      }
    }

    > div {
      display: flex;
      flex-direction: column;
      align-items: flex-end;
      flex: 1 0 auto;
      color: rgba(0, 0, 0, 0.85);


      .labelsList {
        display: flex;
        flex-direction: row;
        margin-top: 5px;

        .labelsDiv {
          padding: 1px 4px;
          border-radius: 5px;
          font-size: 12px;
          margin-left: 5px;
          color: white;
        }

      }
    }
  }
}

.emptyList {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: rgba(161, 161, 164, 0.74);
  font-size: 20px;
  font-weight: bold;
}