.box {
  width: 100%;
  display: flex;
  padding: 10px;
  position: relative;
  background-color: rgba(#000, 0.00);
  transition: all 1.5s;
  &:global{
    &.activeSelect {
      background-color: rgba(#000, 0.05);
      transition: all 0.3s;
    }
  }

  &.me {
    justify-content: flex-end;

    .content {
      position: relative;
      padding: 10px;
      background-color: #EFFDDE;
      text-align: left;
      border: 1px solid #EFFDDE;
      border-radius: 10px;
      margin-right: 10px;
      margin-left: 55px;

      &:after {
        content: '';
        position: absolute;
        width: 0;
        height: 0;
        border-bottom: 15px solid #EFFDDE;
        border-left: 15px solid transparent;
        border-right: 15px solid transparent;
        bottom: 0;
        right: -15px;
        top: auto;
        left: auto;
        border-top: none;
      }

      &:before {
        content: '';
        position: absolute;
        width: 0;
        height: 0;
        border-bottom: 17px solid #EFFDDE;
        border-left: 16px solid transparent;
        border-right: 16px solid transparent;
        border-top: none;
        bottom: -1px;
        right: -17px;
        top: auto;
        left: auto;
      }

      .body {
        justify-content: flex-end;
      }
    }
  }



  &:after {
    content: "";
    cursor: pointer;
    transition: all 0.3s;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;

  }

  &:hover {
    &:after {
      background-color: rgba(#000, 0.05);
    }

  }

  .avatar {
    flex: 0 0 40px;
    width: 100%;
    height: 40px;
    overflow: hidden;
    border-radius: 50%;
    position: relative;

    :global {
      .defaultAvatar {
        font-size: 16px;
      }
    }

    .avatarImg {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }


  }

  .content {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    position: relative;
    padding: 10px;
    background-color: #A8DDFD;
    text-align: left;
    border-radius: 10px;
    margin: 0 10px 0 20px;
    border: 1px solid #A8DDFD;

    .replay {
      padding-left: 20px;
      position: relative;
      z-index: 3;
      &:after {
        position: absolute;
        left: 10px;
        top: 0;
        width: 1px;
        height: 100%;
        background-color: rgb(24, 144, 255);
        content: "";
      }

      .headReplay {
        padding-left: 0;
        padding-right: 0;
      }
    }

    &.nextContent {
      &:after {
        content: none;
      }

      &:before {
        content: none;
      }
    }

    &:after {
      content: '';
      position: absolute;
      width: 0;
      height: 0;
      border-top: 15px solid #A8DDFD;
      border-left: 15px solid transparent;
      border-right: 15px solid transparent;
      top: 0;
      left: -15px;
    }

    &:before {
      content: '';
      position: absolute;
      width: 0;
      height: 0;
      border-top: 17px solid #A8DDFD;
      border-left: 16px solid transparent;
      border-right: 16px solid transparent;
      top: -1px;
      left: -17px;
    }

    .head {
      width: 100%;
      display: flex;
      align-items: center;
      padding: 0 10px;

      span {
        color: #0f0f10;
        font-weight: bold;
        position: relative;
        z-index: 2;
      }

      .time {
        font-weight: normal;
        color: #6e6e6e;
        margin-left: 20px;
      }
    }

    .read {
      position: relative;
      margin-right: 5px;

      span {
        font-size: 12px;
        color: green;

        &:first-child {
          position: absolute;
          top: 3px;
          right: 4px;
        }
      }
    }

    .body {
      flex: 0 1 auto;
      margin-left: 10px;
      display: flex;
      align-items: flex-end;

      span {
        position: relative;
        z-index: 2;
        cursor: text;
      }

      .time {
        font-weight: normal;
        color: #6e6e6e;
        flex: 0 0 auto;
        margin-left: 15px;
      }
    }
  }
}