@import '../../css/colors';

.app.field {
  $min-width: 220px;

  display: inline-flex;
  flex-direction: column;
  //background: inherit;
  align-items: flex-start;
  min-width: $min-width;

  .default.text {
    margin: 1px 0 0 0 !important;
  }
  > label {
    border-radius: 4px;
    font-size: 12px;
    //background: inherit;
    background: white;
    display: inline-flex;
    z-index: 9;
    color: #a8b8c1;
    padding: 0 7px;
    line-height: 19px;
    //margin-bottom: 2px;
    //margin-left: 7px;
    //margin-bottom: -10px;
  }
  > :last-child {
    font-size: 14px;
    width: 100%;
    > .ui.input, input, textarea {
      width: 100%;
    }
  }
  // input, search, text ...
  &:not(.fluid) > :last-child {
    > .ui.input, input, textarea {
      min-width: $min-width;
    }
  }

  &.fluid {
    width: 100%;
  }
}

/*

$min-width: 208px;
.test-fields {
  > .row {
    padding: 0!important;
  }
}

.app-field {
  padding-top: 7px;
  padding-bottom: 7px;
  &.text-field-placeholder {
    color: $light-grey;
  }
  > label {
    display: block;
    font-size: 12px;
    color: $grey;
  }
}

.field-input {
  .input:not(.fluid) {
    min-width: $min-width;
  }
  > label {
    // margin-bottom: -6px;
  }
}

.field-span {
  > label {
    margin-bottom: 0;
  }
  > span {
    padding-top: 4px;
    display: block;
    padding-bottom: 6px;
    line-height: 17px;
  }
}

.field-dropdown {
  .ui.dropdown:not(.fluid) {
    > div {
      min-width: $min-width - 19.5px;
    }
  }
}

.field-checkbox {
  > label {
    margin-bottom: 4px;
  }
}

.field-textarea {
  textarea {
    background: none;
    border: none;
    resize: none;
  }
  &.fluid {
    textarea {
      width: 100%;
    }
    .textarea-container {
      width: 100%;
    }
  }

  .textarea-container {
    padding-top: 4px;
    padding-bottom: 4px;
    border-bottom: 2px solid $light-blue;
  }
}

.field-date {
!*  > label {
    margin-bottom: -6px;
  }*!
  .field {
    padding-top: 0;
  }
  input {
    background: none !important;
  }
}

.field-time {
  &.fluid .container {
    display: flex;
    input {
      min-width: 0;
      width: 0;
    }
  }
  &:not(.fluid) {
    min-width: $min-width;
  }
  input {
    flex: 1 0 auto;
    padding-top: 4px;
    padding-bottom: 5px;
    background: none;

    border: none;
    border-bottom: 2px solid $light-grey;
    outline: none;
    &:focus {
      border-bottom: 2px solid $light-blue;
    }
  }
}
*/
