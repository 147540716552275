.component {

  :global {
    .grid {
      padding-top:10px;
      padding-bottom: 50px;
    }
    .ui.grid > .row {
      padding-bottom: 5px;
      padding-top: 5px;
      // fix dropdown icon's that was shifted a little bit inside grid for some reason
      .ui.dropdown {
         line-height: 1;
         padding-top: 6px;
      }
    }

    .group-headers-row {
      &:not(:first-child) {
        padding-top: 25px !important;
      }
    }
    .group-header {
      font-size: 18px;
      font-weight: normal;
    }
    .additional-button {
      margin-left: 5px;
      margin-right: 5px;
    }
    .description-modal-header {
      display: flex !important;
      justify-content: space-between;
    }
    .expand-grid-column-60 {
      width: 60% !important;
    }
    .processes {
      max-width: 500px;
      .item {
        padding-top: 10px !important;
        padding-bottom: 10px !important;
      }
    }
  }
}