.map {
  width: calc(100vw - 30px);
  height: calc(100vh - 80px);
  margin: 0 auto;
  z-index: 0;
}

.depotLabel {
  &:global(.icon) {
    color: rgba(2, 86, 8, 0.6);
    box-shadow: 0 0 1px 1px #494949;
  }

  &:global(.icon):hover {
    color: rgba(2, 86, 8, 1);
    box-shadow: 0 0 1px 1px #494949;
    z-index: 999;
  }
}
