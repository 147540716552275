@import '../../css/colors';

.component {
  :global {
    .content {
      display: flex;
      max-width: 1000px;
      margin: 0 auto;
    }
    .calendar-plan {
      align-items: stretch;
      height: 600px;
      flex: 1;
    }
    .calendar-navigator {
      margin-bottom: 20px;
    }

    .right-container {
      padding-left: 45px;
      padding-top: 10px;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      .ui.header {
        max-width: 200px;
      }
    }

    .info-group {
      > * {
        padding-top: 10px;
        padding-bottom: 10px;
      }
      margin-bottom: 20px;
    }
  }
}

.dayNavigator {
  font-size: 12px;
  > *:not(:last-child) {
    margin-right: 15px;
  }
  cursor: pointer;
  color: $light-blue;
  > * {
    &:hover {
      color: darken($light-blue, 5%);
    }

    &:active {
      color: darken($light-blue, 10%);
    }
  }
}