@import '../../css/colors';

.app.procurement-planning.add-purchase-details-view {
  .input-fields {
    display: flex;
    flex-direction: column;
    min-width: 200px;
    max-width: 300px;
    padding-right: 20px;
    > *:not(:first-child) {
      margin-top: 10px;
    }
  }
  .file-list-container {
    flex: 1;
  }
  .content-container {
    display: flex;
    justify-content: flex-start;
  }
  .not-defined-field {
    span {
      color: $light-grey;
    }
  }
  .hide-search-results {
    .results {
      display: none !important;
    }
  }
}