@import "../variables";

.mobileContainer {
  padding: $routePadding !important;
  height: calc(100vh - #{$topBarHeight} - #{$topHeaderHeight});
  overflow-y: auto;
}

.form {
  > *:not(:first-child) {
    margin-top: 10px;
  }
}

.emptyForm {
  height: 100px
}