.assigneePopover {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  color: #a1a1a4;

  > svg {
    margin: 0 2px 0 5px;
  }
}