.mainTaskArea {
  display: flex;
  flex-direction: row;
  padding-top: 15px;
  width: 100%;
  min-height: 15vh;
  max-height: 25vh;
  overflow: hidden;

  .desc {
    width: 100%;
    padding: 0 5px;
    height: 100%;

    .pDesc {
      height: 90%;
      max-height: 200px;
      overflow: auto !important;
      padding-bottom: 20px !important;

    }

    .descHeader {
      width: 100%;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;

      > div {
        font-size: 11px;
        margin-bottom: 0.5em;
        color: #1890ff;

        &:hover {
          font-weight: 600;
          cursor: pointer;
        }
      }

      > h4 {
        font-weight: 600;
      }
    }

    .emptyDesc {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      width: 100%;
      height: 100%;

      > div {
        display: flex;
        align-items: center;
        padding: 3px;

        :first-child {
          margin-right: 3px;
        }
      }
    }

    .addDesc {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      width: 100%;
      height: 100%;

      > div {
        display: flex;
        align-items: center;
        padding: 3px;

        :first-child {
          margin-right: 3px;
        }

        &:hover {
          cursor: pointer;
          border: 1px solid rgba(128, 128, 128, 0.34);
          border-radius: 5px;
          background-color: rgba(220, 220, 220, 0.24);
        }
      }

    }

    > p {
      overflow: auto;
      padding: 10px;
    }


    .cancle {
      margin-left: 10px;

      &:hover {
        cursor: pointer;
        font-weight: 600;
      }
    }

    .addSubDone {
      margin-left: 10px;
      color: #1890ff;

      &:hover {
        cursor: pointer;
        font-weight: 600;
      }
    }

    .addSubDis {
      margin-left: 10px;
      color: rgba(128, 128, 128, 0.66);

      &:hover {
        cursor: not-allowed;
      }
    }
  }

  .subtasks {
    width: 100%;
    padding: 0 5px;
    overflow: hidden;
    height: 100%;

    .fixedHeader {

    }

    .subtasksHeader {
      background-color: #f5f7f9;
      width: 100%;
      z-index: 2;
      position: sticky;
      top: 0;

      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;

      > div {
        font-size: 11px;
        margin-bottom: 0.5em;
        color: #1890ff;

        &:hover {
          font-weight: 600;
          cursor: pointer;
        }
      }

      > h4 {
        font-weight: 600;
      }
    }


  }
}