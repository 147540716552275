.container {
  display: flex;
  > * {
    margin-right: 20px;
    display: inline-block;
    label {
      display: block;
      padding-bottom: 4px;
    }
  }
}
.dateInput {
  display: inline-block;
}
.buttonContainer {
  margin-top: 24px;
}

.jornalContainer {
  text-align: right;
  background-color: #fff;
  position: relative;
  width: 104%;
  left: -34px;
  top: -15px;
  padding: 10px 0;
}

.filterContainer {
  margin-bottom: 10px;
  display: flex;
  flex-direction: column;
  position: absolute;
  right: 20px;
  top: 100px;
  z-index: 999;
  background-color: #fff;
  box-shadow: 0px 9px 10px -3px #455a64;
  width: 250px;
  padding: 10px;
  text-align: left;
}

.filtersClose {
  position: absolute;
  right: 280px;
  top: 20px;
  font-size: 20px;
  z-index: 999;

  &:hover { 
    cursor: pointer;
  }
}

.filterButton {
  background: #9f3333 !important;
  color: white !important;
  border: 1px solid #620000 !important;
  position: relative;
  right: 20px;
}