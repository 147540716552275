.depoLink {
  text-decoration: underline;
  &:hover {
    cursor: pointer;
  }
}
.warning {
  background-color: rgb(238, 223, 160);
}
.negative {
  background-color: rgb(240, 154, 154);
}
.positive {

}