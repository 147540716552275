.rerunPositive {

}

.rerunNegative {
  background-color: rgb(240, 154, 154);
}

.rerunWarning {
  background-color: rgb(238, 223, 160);
}
.date {
  white-space: nowrap;
}
