
.cardTaskStatic {
  padding: 8px;
  margin-bottom: 5px;
  background-color: white !important;
  height: 100%;
  overflow-y: auto;

  &:hover {
    cursor: pointer;
  }

  .taskHeader {
    padding: 5px;

    span {
      width: 200px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      font-size: 14px;
      font-weight: bold;
    }

    button {
      padding: 8px;
    }
  }

  .taskContent {
    padding: 5px;
    padding-bottom: 5px !important;

    .descAndAvatar {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;

      :first-child {
        display: flex;
        align-content: center;
      }
    }

  }
}