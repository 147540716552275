.assigneeChartAria {
  width: 100%;
  max-height: 250px;
  max-width: 300px;
  margin: 0 auto;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;

}

.noData {
  display: flex;
  align-items: center;
  justify-content: center;
  color: gainsboro;
  font-size: 16px;
  font-weight: bold;
  height: 85%;
}