.read_all {
  background: #cb6c6c !important;
  color: white !important;
  font-weight: 400 !important;
  transition: 0.8s !important;
}

.read_all:hover {
  background: #ce4747 !important;
  color: white !important;
  transition: 0.8s !important;
}

.task_element {
  list-style: none;
  margin-bottom: 10px; 
  padding: 10px;
  background: #efefef;
  transition: 0.8s;
}     

.task_element:hover {
  background-color: #fbe2e2 !important;
  transition: 0.8s;
}

.ui.modal>.content>.description {
  width: 100%;
}

.custom-calendar .ant-picker-calendar-header .ant-picker-calendar-mode-switch {
  display: none;
}

.custom-calendar .ant-select.ant-select-sm.ant-picker-calendar-year-select.ant-select-single.ant-select-show-arrow {
  display: none;
}

.download.order:hover {
  cursor: pointer;
  background-color: #e0e1e2 !important;
}