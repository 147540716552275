.app.events-for-audit-results {
  display: flex;
  flex-direction: row;

  .advices {

    height: 70vh;
    overflow-y: auto;
  }

  .plan-events {
    margin-left: 25px;
    flex: 1;
  }
  .recommendations {
    margin-left: 30px;
  }
  .reasons {
    margin-left: 30px;
    max-width: 200px;
  }
  .pagination-container {
    display: flex;
    justify-content: center;
  }
}