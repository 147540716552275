.pr-point-details-chart {

	.ui.table {
		margin: 0;
	}

	.chart-details-back.ui.button {
		position: absolute;
		top: 7px;
		left: 8px;
	}

	tr {
		cursor: pointer;
	}
}