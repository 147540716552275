.container {
  width: 100%;
  height: calc(100% - 15px);
  overflow: hidden;
  display: flex;
  background-color: #ffffff;

  :global {
    .defaultAvatar {
      position: absolute;
      border-radius: 50%;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      color: #ffffff;
      font-size: 24px;
    }

    .ant-checkbox-wrapper {
      align-items: center;
      > span {
        &:last-child {
          width: 100%;

          > div {
            &:after {
              left: -32px;
            }
          }
        }
      }

      &.ant-checkbox-wrapper-checked {
        > span {
          &:last-child {
            > div {
              &:after {
                background-color: rgba(0, 0, 0, 0.05);
              }
            }
          }
        }
      }
    }
  }
  .typeListItem{
    width: 100%;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .chatsList {
    height: 100%;
    overflow: auto;
    flex: 0 0 350px;
    border-right: 1px solid #f0f0f0;
    position: relative;
    padding: 10px;
    padding-top: 0;

    .head {
      display: flex;
      width: 100%;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 20px;
      position: sticky;
      top: 0;
      background: #fff;
      z-index: 999;
      height: 50px;
    }

    .addBtn {
      flex: 0 0 auto;
    }

    .searchInput {
      margin-right: 20px;
    }
  }

  .messageBox {
    height: 100%;
    overflow: hidden;
    flex: 1 1 auto;
    position: relative;
    padding: 12px 25px;
    display: flex;
    flex-direction: column;

    .head {
      border-bottom: 1px solid #f0f0f0;
      padding-bottom: 12px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      flex: 0 0 auto;
      position: relative;
      .countUsers{
        font-size: 14px;
        font-weight: normal;
        color: #6e6e6e;
      }
      .headSelectedMessage{
        position: absolute;
        top: -200%;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: #ffffff;
        z-index: 2;
        display: flex;
        align-items: center;
        justify-content: space-between;
        opacity: 0;
        transition: all 0.3s;
        &.active{
          top: 0;
          opacity: 1;
        }
        >div{
          button{
            margin-right: 20px;
          }
        }
      }
      >span {
        text-align: center;
        font-size: 24px;
      }
    }

    .chatNotSelected {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 44px;
    }

    .loadWrap {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .messageWrap {
      flex: 1 1 auto;
      display: flex;
      flex-direction: column-reverse;
      overflow: auto;
      position: relative;
      padding-right: 75px;
      .loadMessage{
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }

    .bottomBtn {
      position: relative;
      bottom: 50px;
      align-self: flex-end;
      margin-right: 20px;
      z-index: 10000;
      transition: all 0.3s;
      opacity: 0;

      &.visible {
        opacity: 1;
      }

      sup {
        top: 15%;
        right: 15%;
        display: flex;
        align-items: center;
        justify-content: center;

        span {
          height: auto;
          display: flex;
          align-items: center;
          justify-content: center;
          line-height: normal;
        }
      }

      button {
        height: auto;
        width: auto;

        span {
          font-size: 46px;
        }
      }

    }
    .reply{
      display: flex;
      align-items: center;
      justify-content: space-between;
      .replyWrap{
        display: flex;
        align-items: center;
        flex: 1 1 auto;
        .icon{
          width: 35px;
          height: 35px;
          svg{
            width: 100%;
            height: 100%;
            fill: #1890ff;
          }
        }
      }
      .replyContent{
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        flex: 1 1 auto;
        .text{
          text-overflow: ellipsis;
          white-space: nowrap;
          overflow: hidden;
          padding: 0 15px;
          width: 600px;
        }
      }
    }
    .actionWrap {
      flex: 0 0 auto;
      display: flex;
      align-items: flex-end;

      button {
        flex: 0 0 auto;
      }
    }
  }
}
.forwardedMessage{
  display: flex;
  align-items: center;
  justify-content: space-between;
  .count{
    font-weight: bold;
    font-size: 18px;
  }
  .icon{
    width: 35px;
    height: 35px;
    svg{
      width: 100%;
      height: 100%;
      fill: #1890ff;
    }
  }
}