.rowTitle {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  .editBtn {
    color: grey;

    &:hover {
      cursor: pointer;
      font-weight: 600;
    }
  }
}

.barArrDesc {
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-wrap: wrap;

  .labelsDiv {
    margin-top: 5px;
    padding: 1px 4px;
    border-radius: 5px;
    font-size: 12px;
    margin-left: 5px;
    color: white;
  }

  .barAssig {
    margin-top: 5px;
    margin-left: 5px;
    color: black;
    font-weight: 500;
  }
}

.actionBtn {
  > i {
    &:hover {
      cursor: pointer;
    }
  }
}