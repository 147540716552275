@import '../../css/colors';

.app.add-recommendation-modal {
  &.ui.modal {
    color: black;
  }
  .buttons {
    display: flex;
    justify-content: space-between;
  }
  .checked-question-row {
    background: $light-grey3;
  }
  .ui.table tr {
    cursor: pointer;
  }
  .advice-textarea {
    width: 100%;
    border: none;
    margin-bottom: 20px;
  }
  .highlight-question {
    background: $light-green2;
  }
  .highlight-recommendation {
    background: $light-green2 !important;
  }
}