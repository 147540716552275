.wrap {
  height: 100%;
  display: flex;
  flex-direction: column;

  .body {
    flex: 1 1 auto;
    background-color: #ffffff;
    border-radius: 0 0 14px 14px;
    padding: 21px 10px 0px 20px;

    :global {
      .MuiTab-textColorInherit {
        border-radius: 4px;
        color: #000000CC;
        font: normal normal normal 11px/24px Roboto;
        transition: all 0.3s;
        min-width: auto;
        min-height: auto;
        padding: 5px 10px;
        margin: 0 5px;

        &.Mui-selected {
          background: #607D8B 0% 0% no-repeat padding-box;
          color: #FFFFFF;
          transition: all 0.3s;
        }
      }

      .MuiTabs-indicator {
        display: none;
      }
    }
  }
}
