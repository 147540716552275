@import './colors';

.ui.modal {
  top: 15%;
  /*position: fixed !important;*/
  /*  height:auto;
    top:auto;
    left:auto;
    bottom:auto;
    right:auto;*/
}

.ui.dimmer {
  background-color: rgba(0, 0, 0, .45);
}

$border-radius: 2px;

.ui.grid:not(.padded) {
  .row {
    padding-top: .5rem !important;
    padding-bottom: .5rem !important;
  }
}

// Apply font's
button, input, optgroup, select, textarea {
  font-family: Roboto, sans-serif !important;
}

.ui.header {
  font-family: Roboto, sans-serif;
}

body #root {
  font-family: Roboto, sans-serif;
}

table {
  font-family: Roboto, sans-serif;
}

button, input, optgroup, select, textarea {
  font-family: Roboto, sans-serif !important;
}

.ui.input {
  font-family: Roboto, sans-serif;
}

// Change checkbox color
.ui.checkbox input:checked ~ .box::before, .ui.checkbox input:checked ~ label::before,
.ui.checkbox input:checked:focus~label:before {
  background: $light-blue;
  border: 1px solid transparent;
}

.ui.checkbox input:checked ~ .box::after, .ui.checkbox input:checked ~ label::after {
  color: white !important;
}

.dropdown-wrap-menu.ui.dropdown {
  .menu {
    overflow-y: auto;
    min-width: auto;
    max-height: 300px;
    // max-height: 500px;
    max-width: 400px;

    & > * {
      white-space: normal;
    }
  }
}

.search-wrap-results.ui.search {
  .results {
    overflow-y: auto;
    max-height: 300px;
  }
}


.input-borderless.ui.input {
  > input {
    border: none;
    word-wrap: break-word;
    word-break: break-all;
  }
}

// Segment
.ui.segment, .ui.segments {
  // Widget style
  &.widget {
    box-shadow: 0 19px 36px #00000029;
    border-radius: 14px;
    border: 0;
  }
}

.alt-semantic {
  // Table
  .ui.table {
    font-size: 14px;
    border: none;


    &.selectable {
      tr {
        cursor: pointer;
      }
    }

    tbody {
      box-shadow: 0 1px 4px $light-grey;
    }

    tr td {
      border: none;
      border-bottom: 1px solid $light-grey2;
    }

    th {
      color: $grey;
      font-weight: normal;
      background: none;
      border: none;
      box-shadow: none;
    }
  }

  // Sortable table
  .ui.table.sortable {
    thead th {
      background: $light-grey3;
      border-left: none;
      border-bottom: 1px solid $light-grey2;
      padding-top: 10px;
      padding-bottom: 10px;
      padding-left: 12px;
      font-weight: bold;
      font-size: 13px;
      // fix: change column width on apply sort to column
      &:not(.sorted)::after {
        display: inline-block;
        font-weight: 400;
        content: "\f0d7";
        opacity: 0;
        font-style: normal;
      }
    }

    td {
      padding-top: 14px;
      padding-bottom: 14px;
      padding-left: 12px;
    }

    tbody {
      box-shadow: none;
    }
  }
}

// Tab, navigation style
.navigation {
  .ui.tabular.menu {
    color: black;
    margin-bottom: 15px;
    border: none;
    align-items: center;
    font-size: 16px;
    border-bottom: 2px solid transparent;

    .item {
      font-family: Roboto, sans-serif;
      font-size: 16px;
      font-weight: 500;
      padding: 0 0 3px 0;
      border: none;

      &.active {
        color: $light-blue;
        border-bottom: 2px solid $light-blue;
      }

      &:not(:first-child) {
        margin-left: 70px;
      }
    }
  }
}

// Step
.ui.steps {
  .step {
    padding: 10px 20px;

    &::before {
      font-size: 20px;
    }
  }

  // font-family: Roboto, sans-serif; // This font don't have icons for completed steps
}


// Button
.button-primary.ui.button {
  background-color: $light-blue;
  border-radius: 2px;
  color: white;
  font-size: 14px;
  font-weight: 500;
  font-family: Roboto, sans-serif;

  &:hover {
    background-color: darken($light-blue, 5%);
  }

  &:active {
    background-color: darken($light-blue, 10%);
  }
}

// Buttons group
.ui.buttons {
  .ui.dropdown {
    border-bottom: none;
  }

  .ui.dropdown .icon {
    color: white;
  }

  .ui.button {
    font-size: 14px;
    font-weight: 500;
    font-family: Roboto, sans-serif;
    background-color: $light-blue;
    color: white;

    &:hover {
      background-color: darken($light-blue, 5%);
    }

    &:active {
      background-color: darken($light-blue, 10%);
    }
  }

  .ui.button {
    border-radius: 0;
  }

  &.ui.buttons .button:first-child {
    border-top-left-radius: $border-radius;
    border-bottom-left-radius: $border-radius;
  }

  &.ui.buttons .button:last-child {
    border-top-right-radius: $border-radius;
    border-bottom-right-radius: $border-radius;
  }

  .ui.dropdown .menu .item {
    font-weight: normal;
    font-size: 14px;
    //font-family: Roboto, sans-serif;
  }
}

// Button without borders, just text
.button-link.ui.button {
  color: $light-blue;
  background-color: transparent;
  box-shadow: none;

  &:hover {
    color: darken($light-blue, 5%);
  }

  &:active {
    color: darken($light-blue, 10%);
  }

  &:disabled {
    color: $grey;
  }
}


// Button without borders with dashed
.button-dashed-link.ui.button {
  color: $light-blue;
  background-color: transparent;
  box-shadow: none;
  border-bottom: 2px dashed;
  border-radius: 0;
  align-self: center;
  padding-left: 0;
  padding-right: 0;
  padding-bottom: 5px;
  margin-bottom: 7px;
  font-weight: normal;

  &:hover {
    color: darken($light-blue, 5%);
  }

  &:active {
    color: darken($light-blue, 10%);
  }

  &:disabled {
    color: $grey;
  }
}

// Button, basic without borders
.button-basic-without-borders.ui.button.basic {
  border: none;
  box-shadow: none;
  padding-left: 0;
  padding-right: 0;
  background: none;

  &:hover {
    background: none !important;
  }

  &:focus {
    background: none !important;
  }

  &.ui[class*="right labeled"].icon.button {
    padding-right: 40px !important;
  }

  &.ui.left.labeled.icon.button {
    padding-left: 40px !important;
  }

  .icon {
    background: none;
  }

  &:active {
    background: none !important;
  }
}

.ui.secondary.vertical.pointing.menu .item {
  border-right-style: none;
  border-right-width: 0;

  margin: 0 0 0 -2px;
  border-left-style: solid;
  border-left-color: transparent;
  border-left-width: 2px;
}
.ui.secondary.vertical.pointing.menu {
  border-right-width: 0;
  border-right-style: none;

  border-left-width: 2px;
  border-left-style: solid;
  border-left-color: rgba(34,36,38,.15);
}