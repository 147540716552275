.tagsList {
  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: sticky;
    top: 0;
    background-color: white;
    border-bottom: 1px solid rgba(0, 0, 0, 0.07);
    padding-bottom: 3px;


    .addTagIcon {
      &:hover {
        cursor: pointer;
      }
    }
  }

  .addTagItem {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: row;
    border-bottom: 1px solid #f0f0f0;
    height: 40px;

    .editActions {
      justify-self: flex-end;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: flex-start;
      padding-bottom: 3px;
    }
  }


  .body {
    padding-top: 10px;
    overflow-y: auto;

    .editTagItem {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      flex-direction: row;

      .editActions {
        justify-self: flex-end;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-start;
        padding-bottom: 3px;
      }
    }

    .delIcon {
      display: none;
    }

    .listItem {
      &:hover {
        cursor: pointer;

        .delIcon {
          display: flex;

          &:hover {
            cursor: pointer;
          }
        }
      }
    }

  }

  .loadingList {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .emptyList {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }

}

