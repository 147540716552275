.list {
  &:global(.ui.selection.list) > :global(.item) {
    color: black;
  }
  &:global(.ui.selection.list) > :global(.item:hover) {
    color: black;
  }
}
.selectedItem {
  background: #c5f2ff !important;
}