.hiddenDeleteIcon {
  i:global(.delete.icon) {
    display: none;
  }
  a:global(.ui.label) {
    background: white;
  }
}
.bottomButton:global(.ui.button) {
  margin-top: 14px;
}

.formToggleButton:global(.ui.buttons) {
  margin-top: 16px;
}
