.commentBlock {
  width: 100%;

  .mainComment {
    padding-top: 10px;
    width: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;

    .comDesc {
      align-self: flex-start;
      font-size: 10px;
      color: #a0a0a0;
    }

    :global {
      .Mui-focused .MuiOutlinedInput-notchedOutline {
        border-color: #1890ff !important;
      }

      .MuiInputLabel-outlined.MuiInputLabel-shrink {
        color: #1890ff !important;
      }

      .MuiSvgIcon-colorPrimary {
        color: #1890ff !important;
      }
    }

  }

  .comLoading {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    z-index: 3;
    color: rgba(0, 0, 0, 0.64);
    font-size: 24px;
    background-color: rgba(0, 0, 0, 0.08);
    opacity: 0.5;
    box-shadow: 0 0 5px 5px #f5f7f9 inset;

    > div {
      color: rgba(0, 0, 0, 0.64);
    }
  }

  .comList {
    width: 100%;
    padding: 10px 20px;

    > div {
      max-width: 1500px !important;
    }
  }
}
