@font-face {
  font-family: "Roboto";
  font-weight: normal;
  font-style: normal;
  src: url("../assets/fonts/Roboto-Regular.ttf")
}
@font-face {
  font-family: "Roboto";
  font-weight: 500; /* Medium */
  src: url("../assets/fonts/Roboto-Medium.ttf");
}
@font-face {
  font-family: "Roboto";
  font-weight: bold; /* 700 */
  src: url("../assets/fonts/Roboto-Bold.ttf");
}
@font-face {
  font-family: "Roboto";
  font-weight: 200;
  src: url("../assets/fonts/Roboto-Light.ttf");
}
@font-face {
  font-family: "Roboto";
  font-weight: 100;
  src: url("../assets/fonts/Roboto-Thin.ttf");
}


body {
  margin: 0;
  padding: 0;

}

.sidebar-block.sidebar-expand > div > a {
  display: inline !important;
}

.axwork-row {
  margin-top: 50px !important;
}

.alt-semantic .ui.table td.positive, .alt-semantic .ui.table tr.positive {
  background: #e0ff98!important;
  color: #2c662d!important;
}

.dx-toolbar-after {
  display: none !important;
}

.dx-toolbar .dx-toolbar-before {
  padding-right: 0px !important;
  display: flex;
  width: 100%;
  justify-content: flex-end;
}

.MuiChip-root.MuiChip-outlined.MuiChip-deletable {
  width: 170px;
}