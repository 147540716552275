.list {
  display: flex;
  flex-direction: column;
  width: 200px;
  max-height: 200px;
  overflow: auto;
}

:global(.ui).item {
  margin-bottom: 5px !important;
  min-height: initial;
 }

.dateWrapper {
  width: 400px;
  display: flex;
  justify-content: space-between;
}
.closeButton {
  background: #dcdcdc !important;
}
.closeButtonWrapper {
  margin-bottom: 15px;
  display: flex;
  justify-content: flex-end;
}
.rangePicker {
  width: 300px !important;
}
.filterButton {
  display: flex !important;
  align-items: center;
  justify-content: space-between;
  > p {
    margin: 0;
  }
}