.selectedBrigadeType {
  cursor: pointer;

  &:hover {
    background-color: rgb(202, 203, 205);
  }
}

.accordion :global(.active.content){
  padding-left: 35px !important;
  padding-right: 10px !important;
  padding-bottom: 10px !important;
}

.seriesGrid {
  :global(.column) {
    padding: 4px !important;
  }
}