.tabContents {
  margin-top: 10px !important;
  justify-content: space-around;
}

.info {
  display: flex;
}

.chartBlock {
  margin-right: 20px;
}

.title {
  text-align: center;
}

.executeContent {
  text-align: center;
}

.additionalInfo {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  margin-bottom: 10px;
}

.block {
  background: #ededed;
  border-radius: 4px;
  padding-top: 15px;
  
  :hover {
    cursor: pointer;
  }
}