.AddWorkListItem {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;

  > div {
    font-weight: bold;
    width: 111px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    > span {
      font-weight: normal;
      text-decoration: underline;
      cursor: pointer;
    }
  }
}

