.header {
  flex: 0 0 auto;
  display: flex;
  background: #F5F7F9 0 0 no-repeat padding-box;
  padding: 15px 45px;
  border-radius: 14px 14px 0 0;
  box-shadow: 0px 19px 36px #00000029;
  position: relative;
  z-index: 2;
  justify-content: space-between;

  .avatar {
    position: relative;
    width: 120px;
    flex: 0 0 auto;
    margin-right: 30px;

    img {
      background-color: #ffffff;
      position: absolute;
      width: 100%;
      top: -48px;
      left: 0;
      height: 120px;
      border-radius: 50%;
      box-shadow: 0 3px 6px #00000029;
      border: 5px solid #C1C8D8;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .addPhoto {
      background-color: #ffffff;
      position: absolute;
      width: 100%;
      top: -48px;
      left: 0;
      height: 120px;
      border-radius: 50%;
      box-shadow: 0 3px 6px #00000029;
      border: 5px solid #C1C8D8;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;

      i {
        margin-bottom: 15px;
        color: #C1C8D8;
      }
    }
  }

  .wrapper {
    display: flex;
  }


  .depotInfo {
    flex: 0 0 auto;
    text-align: right;

    .addProtBtn {
      margin-top: 10%;
      color: white;
      background-color: #607D8B;
    }
  }

  h2 {
    font: normal normal bold 16px/32px Roboto;
    letter-spacing: 0;
    color: #43425D;
    margin: 0 0 8px;
    line-height: normal;
  }

  p {
    font: normal normal normal 12px/32px Roboto;
    letter-spacing: 0px;
    color: #43425D;
    margin: 0 0 6px;
    line-height: normal;
  }

  //span {
  //  font: normal normal normal 12px/32px Roboto;
  //  letter-spacing: 0px;
  //  color: #C1C8D8;
  //  line-height: normal;
  //}
}