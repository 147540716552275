@import '../../../css/colors';

.app.audit-control.process-selector {

  .processes-list {
    padding-top: 10px;
    padding-bottom: 20px;
  }

  .processes-dropdown {
    $levelPad: 16;
    &:not(:last-child) {
      margin-bottom: 20px;
    }

    .level-1 {
      padding-left: $levelPad !important;
    }
    .level-2 {
      padding-left: $levelPad*2px !important;
    }
    .level-3 {
      padding-left: $levelPad*3px !important;
    }
    .level-4 {
      padding-left: $levelPad*4px !important;
    }
  }
  .picked-processes-header{
    font-size: 12px;
    color: $grey;
    margin-top: 15px;
  }
}