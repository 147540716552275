.fullTaskArea {
  position: relative;
  min-height: calc(100% - 15px);
  display: flex;
  width: 100%;
  flex-direction: row;
  padding: 0 19% 0 180px;

  :global {
    .MuiButton-textPrimary {
      color: #1890ff !important;
    }
  }

  hr {
    width: 100%;
    margin: 10px 0;
  }

  .backButton {
    position: absolute !important;
    top: -15px;
    left: -15px;
    height: calc(100% + 30px);
    padding: 0 50px;


    > span:first-child {
      align-self: flex-start;
      position: sticky;
      top: 21px;

      svg {
        margin-left: 5px;
      }
    }
  }

  .taskBox {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;


    .taskHeader {
      display: flex;
      justify-content: space-between;
      flex-direction: row;
      align-items: center;
      width: 100%;
      padding-right: 20px;
      border-bottom: 1px solid rgb(219, 219, 219);
    }
  }

  .controlBar {
    position: fixed !important;
    top: 0px;
    right: 0px;
    height: 100%;
    margin: 0px 0px 0px 0px;
    padding: 70px 15px 0 15px;
    width: 18%;
    border-left: 1px solid rgb(219, 219, 219);
    background-color: white;

  }
}

.errView {
  width: 100%;
  height: 90%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  > span {
    font-size: 20px;
  }

  .backBtn {
    cursor: pointer;
    font-size: 14px;
    margin-top: 20px;
    text-decoration: underline;
    color: #1A73E8;

    &:hover {
      transform: scale(1.1);
      transition: transform 0.1s;
    }
  }
}