.buttons {
  text-align: right;
  margin-top: 40px;
}
.stationSelect {
  width: 250px !important;
}
.form {
  color: black !important;

  :global(label) {
    font-size: 14px !important;
    font-weight: normal !important;
  }
}
.title {
  text-align: center;
  color: black;
  padding-bottom: 10px;
}