.app.check-completed {
  text-align: center;

  .complete-icon {
    margin-top: 50px;
  }
  .complete-text {
    font-size: 29px;
    padding-top: 30px;
    padding-bottom: 35px;
  }
  .buttons {
    padding-bottom: 30px;
    :first-child {
      padding-right: 80px;
    }
    :last-child {
      padding-left: 80px;
    }
  }

  .statistics {
    display: flex;
    flex-direction: column;
    align-items: center;
    .stat-text {
      font-size: 18px;
      padding-bottom: 10px;
    }
    .stat-table {
      max-width: 700px;
      margin-bottom: 60px;
    }
  }
}