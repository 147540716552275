.mainAria {
  display: grid;
  align-items: center;
  justify-content: center;
  grid-template-rows:    repeat(2, 100px);
  grid-template-columns: repeat(2, 200px);

  .itemCont {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
  }
}