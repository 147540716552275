.app.views.page-not-found {

  padding-top: 100px;
  text-align: center;
  .page-not-found-text {
    font-size: 30px;
    margin-bottom: 30px;
  }
  .back-to-main-link {
    font-size: 14px;
  }
}