.lcm-railway-tree {
  &.rc-tree li .rc-tree-node-content-wrapper {
    height: auto;
  }

  .rc-tree-node-content-wrapper {
    border: 1px transparent solid;
  }
  .rc-tree-node-selected {
    background-color: #e31414;
    border: 1px transparent solid;
    opacity: 0.8;
    color: white;
    font-weight: bold;
  }
}

