.marker {
  > div {
    font-size: 10px;
    display: inline-block;
    background-color: #b3d6ff;
    color: black;
    padding: 3px 5px;
    border-radius: 5px;
  }
}

.popupContainer {
  width: content-box;
  height: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  position: sticky;
  top: 0;
  > table {
    position: sticky;
    top: 0;
    > thead {
      padding-right: 2px;
      width: 100%;
      position: sticky;
      top: 0;
    }
  }


  .noData {
    font-size: 14px;
    color: rgba(0, 0, 0, 0.3);
  }

}

:global {
  .leaflet-popup-content {
    width: 100%;
    margin: 17px 5px;
  }

  .leaflet-popup-close-button {
    color: black !important;
  }

  .pr-table-for-popup {
    &.ui.table {
      border: 0px;
    }

    &.ui.basic.table {
      border: 0px;
    }

    &.ui.basic.table tbody tr {
      border: 0px;
    }

    &.ui.table tr td {
      border: 0px;
      // rows
      padding: 4px 10px;
    }

    &.ui.table thead th {
      border: 0px;
      // header
      padding: 7px 10px;
      background: #B3D6FF;
      color: #000;
    }

    .content {
      padding: 20px;
    }

    &.ui.table tr:first-child td {
      padding-top: 10px;
    }

    &.ui.table tr:last-child td {
      padding-bottom: 10px;
    }

    .subtitle {
      font-weight: normal;
      color: #777;

    }
  }
}