.wrap {
  width: 100%;
  max-width: 800px;
  margin: 0 auto;
  height: 100%;
  position: relative;
  display: flex;
  padding: 15px;
  align-items: flex-start;

  .imgWrap {
    padding: 15px;
    background-color: #FFFFFF;
    border-radius: 4px;
    flex: 0 0 auto;
    display: flex;
    flex-direction: column;
    img {
      border-radius: 2px;
      overflow: hidden;
      width: 200px;
      height: 200px;
      object-fit: cover;
      display: block;
      margin-bottom: 20px;
    }

    button {
      margin: 0;
      width: 100%;
    }
  }

  .content {
    margin-left: 15px;
    flex: 1 1 auto;
    height: 286px;
    padding: 15px 20px;
    background-color: #FFFFFF;
    border-radius: 4px;

    .head {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 15px;

      h1 {
        font-size: 19px;
        line-height: 25px;
        font-weight: 400;
      }


    }

    hr {
      margin-bottom: 15px;
    }

    .onlineWrap {
      color: #818c99;
      font-size: 12px;
    }

    .mainInfo {
      margin-bottom: 15px;
      position: relative;
    }

    .secondInfo {
      position: relative;
    }

    .editBtnWrap {
      position: absolute;
      right: 0;
      top: -15px;
      padding-left: 5px;
      background-color: #FFFFFF;
      transform: translateY(-50%);

      button {
        color: #818c99;
        text-transform: none;

        svg {
          font-size: 16px;
        }
      }
    }

    .field {
      display: flex;
      align-items: center;
      margin-bottom: 5px;

      button {
        text-transform: none;
        color: #818c99;
      }

      .editField {
        flex: 1 1 auto;
        position: relative;
        height: 22px;

        > div {
          position: absolute;
          left: 0;
          bottom: -4px;

          > div {
            &:after {
              border-bottom-color: #2185d0;
            }
          }
        }
      }

      > span {
        &:first-child {
          max-width: 165px;
          width: 100%;
          color: #818c99;
        }
      }
    }
  }
}