.disabledLabel {
  color: rgba(191, 191, 191, .87);
}

.dropdown {
  margin-bottom: 10px;
}

.quartalAndMonth {
  display: flex;

  > :nth-child(1) {
    flex: 1;
    padding-right: 10px;
  }

  > :nth-child(2) {
    padding-left: 10px;
    flex: 1;
  }
}

.canSelect {
  border-color: rgb(0, 183, 255) !important;
}