.comInspInfoBox {
  width: 100%;
  height: 100%;

  .header {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding: 10px 10px 25px 10px;

    >span {
      color: #43425D;
      font-size: 20px;
      line-height: 20px;
      padding-right: 20px;
    }
  }

  .gridArea {
    display: grid;
    grid-gap: 20px;
    //grid-template-columns: 100px 100px 100px;
    &.gridAreaDaily {
      margin: 0 auto;
      width: 50%;
      min-width: 440px;
    }
    .notSelected {
      color: #525252;
      font-size: 20px;
      //font-weight: bold;
    }
    > div {
      background-color: #FFFFFF;
      border-radius: 10px;
      padding: 20px;
      box-shadow: 0px 19px 36px #00000029;
    }

    .itemOne {
      min-width: 345px;
      min-height: 134px;
      grid-column: 1;
      grid-row: 1;
    }

    .itemTwo {
      min-width: 345px;
      min-height: 134px;
      grid-column: 2;
      grid-row: 1;
    }

    .itemThree {
      min-width: 574px;
      //min-height: 291px;
      min-height: 134px;
      grid-column: 1 / span 3;
      grid-row: 2;
    }

    .itemFour {
      min-width: 520px;
      //min-width: 375px;
      //min-height: 449px;
      min-height: 134px;
      grid-column: 3;
      grid-row: 1;
    }
  }
}

.selectTitle {
  margin-right: 20px;
  font-size: 20px;
}

.header {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 10px 10px 25px 10px;

  >span {
    color: #43425D;
    font-size: 20px;
    line-height: 20px;
    padding-right: 20px;
  }
}
