@import "../variables";

.topHeader {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #ccd4ff;
  height: $topHeaderHeight;
  margin: 0 auto;
}