.container {
  > a:not(:last-child) {
    margin-bottom: 8px;
  }
  > a:global(.ui.label) {
    display: block;
  }
  > a:global(.ui.label):last-child {
    margin-right: .14285714em;
  }
  &:global(.ui.padded.segment) {
    padding: 38px 4px 8px 4px;
  }
  :global(.ui.label) > :global(.icon) {
    margin: 1px 0 0 0;
    :hover {
      cursor: pointer;
    }
  }
}
.addButton {
  &:hover {
    cursor: pointer;
  }
}
.day {
  &:global(.ui.label) {
    text-transform: capitalize;
  }
}