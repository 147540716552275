$gap: 4px;
.listItem {
  display: flex !important;
  color: black;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  > :nth-child(1) {
    flex: 1
  }
  &:hover {
    background-color: #f8f8f8;
  }
}


.list {
  &:global(.ui.list) {
    margin-top: 6px;
    margin-bottom: 16px;
  }
}

.checkedItem {
  background-color: #c5f2ff;
  &:hover {
    background-color: #b3f0ff;
  }
}