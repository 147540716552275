.tempAria {
  overflow: auto;
  position: relative;

  .header {
    position: sticky;
    top: 0;
    background-color: white;
    border-bottom: 1px solid rgba(0, 0, 0, 0.07);


    .popoverStatus {
      position: absolute;
      height: 100%;
      width: 100%;
      top: 0;
      left: 0;
      background-color: transparent;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      padding-left: 5px;

    }

  }

  .spinner {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .selectedTemp {
    padding-top: 10px;
    overflow-y: auto;

    > div {
      padding-bottom: 10px;
    }

    .titleList {
      font-family: monospace;
      font-size: 14px;
      font-weight: 600;
      color: rgb(3, 3, 3);
      border-bottom: 1px solid rgb(0, 0, 0);
    }

    .parag {
      display: flex;
      flex-direction: row;
      align-items: center;

      .colorBlock {
        margin-right: 5px;
        width: 1em;
        height: 1em;
        border-radius: 5px;
      }
    }
  }
}

.statusList {
  z-index: 3;

  > div {
    padding-bottom: 10px;
  }

  .titleList {
    font-family: monospace;
    font-size: 14px;
    font-weight: 600;
    color: rgb(3, 3, 3);
    border-bottom: 1px solid rgb(0, 0, 0);
  }

  .parag {
    display: flex;
    flex-direction: row;
    align-items: center;

    .colorBlock {
      margin-right: 5px;
      width: 1em;
      height: 1em;
      border-radius: 5px;
    }
  }
}