.itemArea {
  display: flex;
  flex-direction: row;

  .squer {
    margin: 0px 10px;
    border-radius: 12px;
    opacity: 0.1;
  }

  .title {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;

    span {
      color: #333333;
    }
  }
}