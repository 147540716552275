.pr-ready-to-work-layout-control { 
	width: 700px;
	.fleet-stats {
		// margin-top: 30px;
	}
}

.pr-ready-to-work-progress-popup {
	&.ui.popup {
		padding: 0;
	}
}