.app.movement-history {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  .for-period {
    padding: 0;
  }
}

.app.movement-map-modal {
  .movement-map {
    height: 60vh;
    width: 100%;
  }
  .group-info {
    display: flex;
    justify-content: flex-start;
    padding-top: 7px;
    padding-bottom: 7px;
   // align-items: flex-end;
    > *:not(:last-child) {
      margin-right: 30px;
    }
    > * {
      padding: 0;
    }
  }
  .widget-header {
    padding-bottom: 0;
  }
}