@import "../../css/colors";

.app.purchases-table {
  .pagination-container {
    display: flex;
    justify-content: center;
    padding-bottom: 20px;
  }
  .row-exceeded-purchase {
    background: $light-red2;
  }
  .ui.selectable.table tbody tr.row-exceeded-purchase:hover {
    background: $light-red !important;
  }
}
.name-cell {
  text-overflow: ellipsis;
  overflow: hidden;
  max-width: 300px;
}
