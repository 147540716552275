@import '../../css/colors';

.app.audit-control.day-view {

  &.processes-popup {
    min-width: 400px;
  }
  .year {
    font-size: 14px !important;
    font-weight: normal;
  }
  .ui.header {
    color: $dark-grey;
  }
  .audit-table {
    border: none;
    font-size: 13px;
    font-weight: normal;
    tr {
      //font-weight: bold;
      cursor: pointer;
    }
    td {
      box-shadow: none !important;
      border: none !important;
    }
    td:first-child {
      border-left: 3px solid transparent !important;
    }
    tr:not(.last-row) td {
      border-bottom: 1px solid $light-grey2 !important;
    }
    .pred-name {
      padding-top: 10px;
      padding-bottom: 10px;
    }
    .fio {
      //vertical-align: bottom;
      //padding-bottom: 6px;
      //font-size: 10px;
      //font-weight: normal;
    }
  }

  .row-fill-red {
    color: $light-red;
    //background-color: lighten($light-red, 22%);
  }
  .row-fill-green {
    color: $light-green;
    //background-color: lighten($light-green, 30%);
  }
  .row-fill-orange {
    color: $light-orange;
    //background-color: lighten($light-orange, 30%);
  }
  .row-fill-blue {
    color: $light-blue;
    //background-color: lighten($light-blue, 30%);
  }
  .row-fill-grey {
    color: $grey;
    background-color: lighten($grey, 50%);
  }

  .selected td:first-child {
    border-left: 3px solid $grey !important;
    // box-shadow: -3px 0 0 $grey !important;
  }
  .buttons {
    display: flex;
    justify-content: space-between;
  }
}
