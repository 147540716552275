.sAndPChartAria {
  max-height: 300px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

}

.noData {
  display: flex;
  align-items: center;
  justify-content: center;
  color: gainsboro;
  font-size: 16px;
  font-weight: bold;
  height: 85%;
}