.buttonsWrapper {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-end;
  gap: 30px;
  .dateAndSendWrapper {
    display: flex;
    align-items: flex-start;
    gap: 100px;
    flex-wrap: wrap;
  }
}

.rowWrapper {
  display: flex;
  justify-content: space-between;
  gap: 14px;
  .rowForm {
    flex-grow: 1;
    & label {
      margin-bottom: 14px !important;
    }
  }
  .deleteButton {
    height: 38px;
  }
  &:first-child {
    .deleteButton {
      margin-top: 28px;
    }
  ;
  }
}
.date {
  flex-direction: row !important;
  label {
  }
}