.component {
  :global {

    .ui.header {
      padding-top: 5px;
      padding-bottom: 5px;
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
    .year {
      font-size: 14px;
      font-weight: normal;
    }
    .audit-date {
      > label {
        font-weight: normal;
      }
    }
    .add-properties {
      display: flex;
      flex-direction: row;
      > div {
        flex: 1;
        > *:not(:first-child) {
          margin-top: 10px;
        }
      }
    }
    .control-buttons {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
    }
  }
}