.collabsible-wrap {
	display: flex;
	align-items: center;

	div {
		flex-grow: 1;
	}
	.minimize-button {
		padding: 0px;
		color: rgb(124, 124, 124);
		z-index: 10;
		&:hover {
			cursor: pointer;
		}
	}

	// When chart is hidden, divider has no text, because of it height will be diffrent
	//   fix: applying this class to divider when chart is hidden
	.minimized-divider {
		margin-top: 21px;
		margin-bottom: 21px;
	}
}