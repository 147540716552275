.half-circle-progress-bar {
  position: relative;
  width: 180px;
  height: 100px;
  .hc-value-container {
    position: absolute;
    font-family: "Lucida Grande", "Lucida Sans Unicode", Arial, Helvetica, sans-serif;
    z-index: 10;
    text-align: center;
    top: 42px;
    left: 0;
    right: 0;
    margin-left: auto;
    margin-right: auto;
    width: 180px;

    .hc-value {
      font-size: 18px;
      height: 26px;
    }
    .hc-value-descr {
      font-size: 13px;
    }
  }
}