$gap: 10px;
$halfGap: $gap / 2;

.searchLocContainer {
  max-width: 700px;
  display: flex;
  justify-content: space-evenly;
  > * {
    flex: 1;
    &:not(:last-child) {
      margin-right: $halfGap;
    }
    &:not(:first-child) {
      margin-left: $halfGap
    }
  }
}

@media only screen and (max-width: 350px) {
  .searchLocContainer {
    flex-direction: column;
    > * {
      margin: 0 !important;
    }
  }
}