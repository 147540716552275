.nameWrap{
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  span{
    font-size: 16px;
    flex: 0 0 auto;
    margin-right: 10px;
  }
}

.searchUserWrap{
  width: 100%;
  height: 500px;
  overflow: auto;
  padding-right: 10px;
  margin-top: 20px;
  .searchUser {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px 0;
  }

}

.users {
  width: 100%;
  display: flex;
  margin-bottom: 20px;
  >span{
    padding-top: 5px;
    font-size: 16px;
    flex: 0 0 auto;
  }
  .selectedUserWrap {
    flex: 1 1 auto;
    display: flex;
    flex-wrap: wrap;

    span {
      margin: 0 10px 5px;
      line-height: normal;

      display: flex;
      align-items: center;
      padding-top: 4px;
      padding-bottom: 4px;
    }
  }
}