.table {
  position: relative;
  &:global(.ui.celled.table tr td:first-child) {
    max-width: 600px;
    min-width: 600px;
    position: sticky;
    left: 0px;
    background-color: white;
  }
  &:global(.ui.celled.table tr th:first-child) {
    max-width: 600px;
    min-width: 600px;
    position: sticky;
    left: 0px;
    background-color: #709AC2;
  }
  &:global(.ui.celled.table tr td:not(:first-child)) {
    max-width: 130px;
    min-width: 130px;
  }
}
