.bodyAddProt {
  overflow: auto;

  .markAria {
    display: flex;
    flex-direction: column;
    justify-content: space-around;

    > div {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-around;
      margin-bottom: 15px;

      span {
        font-weight: bold;
        font-size: 13px;
        color: #43425D;
        width: 135px;
        text-align: center;
      }

      p {
        line-height: 14px;
        font-size: 14px;
        width: 135px;
        text-align: center;
      }


    }
  }

  .leader {
    > span {
      margin-bottom: 16px;
      font: normal normal bold 14px/32px Roboto;
      letter-spacing: 0px;
      line-height: 14px;
      color: #43425D;
      position: relative;

      &:after {
        content: "";
        position: absolute;
        bottom: -2px;
        left: 0;
        width: 140%;
        height: 1px;
        background-color: #C1C8D8;
      }
    }
  }

  .leaderForm {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;

    > div {
      padding: 20px 0px;
    }
  }

  .doc {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 10px 20px 20px 0px;

    .uploadBtn {
      padding-right: 20px;
    }

    > span {
      margin-bottom: 16px;
      font: normal normal bold 14px/32px Roboto;
      letter-spacing: 0px;
      line-height: 14px;
      color: #43425D;
      position: relative;


      &:after {
        content: "";
        position: absolute;
        bottom: -2px;
        left: 0;
        width: 140%;
        height: 1px;
        background-color: #C1C8D8;
      }
    }
  }
}