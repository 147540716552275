.app.polygon-scheme.polygon-filter {
  .control-buttons {
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;

    > * {
      margin-left: 20px;
    }
  }

  .filter-header {
    font-size: 15px;
    font-weight: normal;
  }

  .filter-grid {
    .column {
      > .field {
        margin-top: 10px;
      }
      > .checkbox {
        margin-top: 20px;
      }
    }
  }
}
