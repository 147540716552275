.rightGapSmall {
  & > *:not(:last-child) {
    margin-right: 5px !important;
  }
  :last-child {
    margin-right: 0 !important;
  }
}

.rightGapLarge {
  & > *:not(:last-child) {
    margin-right: 20px !important;
  }
  :last-child {
    margin-right: 0 !important;
  }
}

.rightGapNorm {
  & > *:not(:last-child) {
    margin-right: 10px !important;
  }
  :last-child {
    margin-right: 0 !important;
  }
}
