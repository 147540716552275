.pr-ep-rp-layout-main {
	width: 700px;
}

.pr-ep-rp-layout-hint {
	display: inline-block;
	// Fixed position is hack,
	position: fixed;
	bottom: 15px;
	// left: 59px;
	margin-left: 60px !important;
	border: 2px solid rgba(0,0,0,0.1);
	border-radius: 4px;
	padding: 1rem;
	font-size: 1rem;
	background-clip: padding-box !important;
	box-shadow: none !important;
	.traction-type {
		font-weight: bold;
	}
}


.pr-ep-rp-layout-progress-popup {
	&.ui.popup {
		padding: 0
	}
	.popup-dimmer {
		height: 70px;
		padding: 0;
	}

	popup-traction-type {
		font-weight: 700;
		margin-bottom: 5px;
	}
}