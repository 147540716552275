.columnOrder {
  display: flex;
  flex-direction: column;
}
.gap {
  margin-bottom: 4px !important;
}
.buttonRow {
  height: 20px;
  width: 90px;
}
.wentBeyond {
  background-color: rgb(245, 64, 50);
}
.ok {
  background-color: rgb(62, 207, 62);
}
.tableSubtitle {
  display: flex;
  justify-content: space-between;
}
.customPopup {
  &:global(.ui.table tbody tr:first-child) {
    font-weight: 700;
    font-style: italic;
    td {
      padding-bottom: 10px;
    }
  }
}