@import './variables';

.backgroundTransitionNone {
  background: rgba(255, 255, 255, .3) !important;
  transition: background-color 0.5s ease;

  &:hover {
    background: rgba(255, 255, 255, 1) !important;
  }

  user-select: none;
}

.halfTransparentSegment {
  $transparentBg: rgba(255, 255, 255, .3);
  $solidBg: rgba(255, 255, 255, 1);

  &:global(.ui.segment) {
    background: $transparentBg;
    transition: background-color 0.5s ease;

    &:hover {
      background: $solidBg;
    }

    user-select: none;

    :global(.dropdown) {
      background: $transparentBg;
    }
  }

}

.popupFixWidth {
  box-sizing: content-box;
}



.map {
  width: 100%;
  height: calc(100vh - 100px);

  margin: 0 auto;
  z-index: 0;

  :global(.leaflet-marker-icon):global(:not(.marker-cluster)) {
    // remove white background from markers
    background: none;
  }
  // hide default leaflet markers
  :global(.leaflet-marker-icon) {
    border: none;
    box-shadow: none;
  }
}
.compatibleOldMenu {
  .map {
    height: calc(100vh - #{$topBarAndRoutePaddingHeight});
  }
}
.paddingZero {
  padding: 0 !important;
}

.displayBlock {
  display: block !important;
}

.fixedDropdown {
  &:global(.ui.selection.dropdown) {
    border: 1px solid transparent;
  }

  &:global(.ui.disabled.dropdown) {
    opacity: 1;
  }

  :global(.dropdown.icon) {
    visibility: hidden;
  }

  // Multiple dropdown
  a:global(.ui.label) {
    background: none;
    color: black;
    font-weight: normal;

    i {
      display: none;
    }
  }
}

/* This method have some caveats:
  works for tables with one row
  margin-top should be 0
  border-top should be removed to avoid flickering while scrolling */
.tableStickyHeader {
  margin-top: 0 !important;
  border: 0 !important; // remove outer border completely for better look
  thead tr:first-child > th {
    position: sticky !important;
    top: 0;
    z-index: 1002;
  }
}

.fixedInput {
  &:global(.ui.disabled.input) {
    opacity: 1;

    input {
      border: 1px solid transparent;
    }
  }
}

.fixDatePicker {
  :global(.DayPicker_weekHeader) {
    margin-top: -5px;
  }

  :global(.DateRangePicker_picker) {
    z-index: 3;
  }

  :global(.DateInput_fang) {
    margin-top: 1px;
    z-index: 4;
  }
}

.fixedDatePicker {
  opacity: 1 !important;

  :global(.ui.input.disabled) {
    opacity: 1;

    input {
      border: 1px solid transparent;
    }
  }
}

.dropdownFullWidthItems {
  a {
    display: flex !important;
    align-items: center;

    i {
      flex: 1;
      text-align: right;
    }
  }
}

.selectableTable {
  tr {
    cursor: pointer;
  }

  &:global(.ui.table tbody tr:hover) {
    // semantic ui library have important
    background: #dff3ff !important;
  }
}

.fixComplexTableHeaderBorder {
  tr th {
    border-left: 1px solid rgba(34, 36, 38, .1) !important;
  }
}

.verticalGapNorm {
  & > *:not(:last-child) {
    margin-bottom: 10px !important;
  }

  :last-child {
    margin-bottom: 0 !important;
  }
}