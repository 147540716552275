@import '../variables';

.monthBox {
  display: inline-block;
  height: $topHeaderHeight;
  line-height: $topHeaderHeight;
  padding: 0 60px;
  cursor: pointer;
  text-transform: capitalize;
  font-size: 16px
}