@import '../../css/colors';

.loadingDimmer {
  &:global(.ui.dimmer) {
    padding: 80px;
    z-index: 10;
  }
}
:global(.ui.compact.table) {
  td.dataIsEmpty {
    padding: 40px;
    text-align: center;
    font-size: 18px;
  }
}

.changedByOrder {
  color: $red;
  font-weight: bold;
}