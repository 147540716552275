:global {
  .defaultAvatar {
    position: absolute;
    border-radius: 50%;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #ffffff;
    font-size: 18px;
  }
}


.box {
  margin-top: 30px;

  .item {
    display: flex;
    padding: 10px;
    background-color: rgba(#000, 0.00);
    transition: all 0.3s;
    cursor: pointer;
    &:hover {
      background-color: rgba(#000, 0.05);
    }

    .avatar {
      flex: 0 0 40px;
      height: 40px;
      border-radius: 50%;
      position: relative;
    }

    .content {
      margin-left: 20px;

      span {
        font-weight: bold;
      }
    }
  }
}

