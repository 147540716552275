.counterAria {
  width: 100%;
  max-height: 250px;
  max-width: 300px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  .totalCnt {
    display: flex;
    justify-content: center;
    align-items: center;
    flex: auto;
    margin: 10% 0 15% 0;

    > span {
      font-size: 5em;
    }

    &:hover {
      cursor: pointer;
    }
  }

  .fullInfo {
    flex: auto;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    flex-wrap: wrap;

    .selected {
      color: #26ac37;
      transform: scale(1.1);
    }

    > div {
      &:hover {
        cursor: pointer;
      }

      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      @media (min-width: 901px) {
        font-size: 24px;
        > span {
          margin: 0 5px;
          font-size: 14px;
        }
      }
      @media (max-width: 900px) {
        font-size: 16px;
        > span {
          margin: 0 5px;
          font-size: 10px;
        }
      }
    }
  }
}

