.component {
  :global {
    .menu.transition {
      max-height: 200px;
      overflow: auto;
    }
    .road {
      font-weight: bold !important;
    }
    .pred {
      margin-left: 10px;
    }
  }
}