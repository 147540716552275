.nameInfo, .nameInfoActive {
  flex: 1 1 auto;
  //background-color: #cbcbcb;
  padding: 10px 15px;
  border-radius: 3px;
  cursor: pointer;

  .fioWrap {
    display: flex;
    align-items: center;
    line-height: normal;
    margin-bottom: 8px;

    .tags {
      span {
        font: normal normal normal 12px/32px Roboto;
        letter-spacing: 0;
        color: #FFFFFF;
        line-height: 20px;
      }
    }

    h2 {
      margin: 0;
    }
  }

  margin-right: 30px;
}

.nameInfo:hover {
  background-color: #e0e0e0;
}

.nameInfoActive {
  background-color: #94b7c7;
}

.nameInfoActive:hover {
  background-color: #a7d2e3;
}