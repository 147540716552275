.pr-failure-charts-grid {


	&.ui.grid {
		padding: 0;
		padding-top: 6px;
		.row:nth-child(1) {
			padding-top: 0;
			padding-bottom: 0;
		}
		.row:nth-child(2) {
			padding-top: 0;
		}
	}
}

.pr-failure-charts-modal {
	.table {
		margin: 0;
	}
	.back-to-count-failures.ui.button {
		position: absolute;
		top: 30px;
		left: 30px;
	}
}