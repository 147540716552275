.selectedWorker {
  cursor: pointer;

  &:hover {
    background-color: rgb(202, 203, 205);
  }
}

.modalPerson {
  width: 100% !important;
  max-width: 1352px;
  min-height: 814px;
  top: 10% !important;

  > div {
    min-height: 814px;

    > div {
      min-height: 814px;
    }
  }
}
