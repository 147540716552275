.formLoader {
  // higher than table headers and multiple-dropdown selected items
  z-index: 1004 !important;
  position: absolute !important;
  background: rgba(255,255,255,.8);
}

input.incorrectTime {
  box-shadow: 0 0 2px 2px #ff00008c !important;
  border: 1px solid rgba(34,36,38,.15) !important;
}