.container {
  padding: 0;
  position: relative;
  overflow: hidden;
  border-radius: 3px;
  line-height: 0;
}
.containerWithImage {
  box-shadow: 0 1px 4px 0 #ababab;
}

.controls {
  transition-duration: 300ms;
  position: absolute;
  top: 0;
  bottom: 0;
  right: -50px;
  padding: 4px 4px 0 6px;
  background: rgba(0,0,0,0.3);
  display: flex;
  flex-direction: column;
  i {
    margin-top: 4px;
    color: white;
    box-shadow: none !important;
  }
  &:hover {
    transform: translate(-50px, 0);
  }
}

.image {
  width: 100%;
  &:hover + .controls{
    transform: translate(-50px, 0);
  }
}


.loadedImage:hover {
  cursor: zoom-in;
}