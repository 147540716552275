
.dropFilter {
  label {
    color: #0f0f10;
    background-color: #f6f6f6;
  }
}

.TextField {
  label {
    font-weight: 700;
    font-size: 15px !important;
  }

  span {
    font-size: 16px;
  }
}