.container {
  display: inline-block;

  label {
    font-size: 14px !important;
    color: black;
    padding: 0 2px 0 0px;
  }

  :global(.ui.basic.button) {
    margin-right: 18px;
    &:hover {
      background: rgba(0,0,0,.04) !important;
    }
    box-shadow: none;
  }
}