.modal {
  :global(.segment .segment) {
    cursor: pointer;
    &:hover {
      background: aliceblue;
    }
  }
}

.locOnRailways {
  :global {
    .row-form {
      margin-top: 10px;
      display: flex;
      align-items: flex-end;
      > * {
        padding: 0;
      }
      > *:not(:last-child) {
        margin-right: 10px;
      }
    }
    .button-row {
      margin-top: 10px;
      text-align: right;
    }
  }
}

.cases {
  &:global(.ui.table) {
    thead th {
      border: none;
    }
    tr td {
      border: none;
    }
    // header
    thead th {
      border-bottom: 1px solid rgba(0, 0, 0, 0.19);
      &:not(:first-child) {
        border-left: 1px solid rgba(0, 0, 0, 0.19);
      }
    }
    .groupCell:not(:last-of-type) {
      border-bottom: 1px solid rgba(0, 0, 0, 0.19);
      text-align: center;
    }
    .smallCell {
      border-left: 1px solid rgba(0, 0, 0, 0.19);
      border-bottom: 1px solid rgba(0, 0, 0, 0.19);
    }
  }
}