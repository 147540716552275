.taskHeaderStatus {
  padding: 1px 4px;
  border-radius: 5px;
  font-size: 14px;
  margin-left: 5px;
  color: white;

  &:hover {
    cursor: pointer;
  }
}

.taskHeaderStatusDisable {
  padding: 1px 4px;
  border-radius: 5px;
  font-size: 14px;
  margin-left: 5px;
  color: white;

  &:hover {
    cursor: default;
  }
}