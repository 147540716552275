.infoMainDiv {
  display: flex;
  flex-direction: row;
  width: 100%;
  margin: 0 auto;
  max-width: 1800px;
  height: 100%;

  .infoMainBody {
    width: 100%;
    padding-right: 25px;
  }

  .infoMainFilter {
    max-width: 20%;

    label {
      color: #0f0f10;
      background-color: #f6f6f6;
    }
  }
}