.buttonsRow {
  display: flex !important;
  flex-direction: row;
  justify-content: space-evenly;
  background: rgb(179, 214, 255);
  border-radius: 3px;
  box-shadow: 0 0 1px 1px #494949;
}
.button {
  display: flex !important;
  div {
    font-size: 14px !important;
  }
  align-items: center;
  justify-content: center;
  flex-basis: 100%;
  text-align: center;
}
.cursorAuto {
  cursor: auto
}
.clickable {
  cursor: pointer;
  transition: background-color ease-in-out 300ms;
  border-radius: 3px;
  &:hover {
    background: rgb(157, 242, 255);
  }
}
