.modalWSMore {
  height: 400px;

  .headerWS {
    flex: 0 0 auto;
    display: flex;
    background: #F5F7F9 0 0 no-repeat padding-box;
    padding: 15px 45px;
    border-radius: 14px 14px 0 0;
    box-shadow: 0px 19px 36px #00000029;
    position: relative;
    z-index: 2;

    .groupName {
      margin-left: 10px;
      font-size: 16px;
    }

    > div {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;

      h3 {
        margin-left: 10px;
      }
    }
  }

  .footerWS {
    flex: 0 0 auto;
    display: flex;
    background: #F5F7F9 0 0 no-repeat padding-box;
    padding: 15px 45px;
    border-radius: 0 0 14px 14px;
    box-shadow: 0px 19px 36px #00000029;
    position: relative;
    z-index: 2;
    justify-content: flex-end;
    align-items: center;
  }

  .modalGrid {
    height: 400px;
    width: 100%;
    display: grid;
    grid-column-gap: 10px;
    grid-row-gap: 1em;
    grid-template-columns: repeat(2, 1fr);
    padding: 15px;

    > div {
      padding: 10px;
      display: flex;
      flex-direction: column;
      width: 100%;
      overflow: hidden;
    }
  }

  .modalOneView {
    height: 400px;
    width: 100%;
    display: grid;
    grid-column-gap: 10px;
    grid-row-gap: 1em;
    grid-template-columns: repeat(1, 1fr);
    padding: 15px;

    > div {
      padding: 10px;
      display: flex;
      flex-direction: column;
      width: 100%;
      overflow: hidden;
    }
  }
}