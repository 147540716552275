.rt-marker {
  position: absolute;
  z-index: 1;
  top: 2* $react-timelines-header-row-height;
  bottom: 0;
  margin-left: -($react-timelines-marker-line-width / 2);
  border-left: $react-timelines-marker-line-width solid;
  opacity: 0;
  pointer-events: none;
}

.rt-marker.rt-is-visible {
  opacity: 1;
}


.rt-marker--now {
  color: $react-timelines-marker-now-background-color;
  border-color: rgba(
    $react-timelines-marker-now-background-color,
    $react-timelines-marker-line-transparency
  );
}

.rt-marker--pointer {
  color: $react-timelines-marker-pointer-background-color;
  border-color: rgba(
    $react-timelines-marker-pointer-background-color,
    $react-timelines-marker-line-transparency
  );
}

.rt-marker--pointer.rt-is-highlighted {
  color: darken($react-timelines-marker-pointer-background-color, 25%);
  border-color: rgba(darken($react-timelines-marker-pointer-background-color, 1), 25%);
}

.rt-marker__label {
  position: absolute;
  bottom: 100%;
  left: 50%;
  display: table;
  min-width: 70px;
  height: $react-timelines-header-row-height;
  padding: 0 10px;
  line-height: 1.1;
  text-align: center;
  background: currentColor;
  transform: translateX(-50%);
  font-size: 16px;
  font-weight: bold;

  &::before {
    $size: 6px;

    position: absolute;
    top: 100%;
    left: 50%;
    margin-left: -$size;
    transform: translateX(-($react-timelines-marker-line-width / 2));
    border-left: $size solid transparent;
    border-right: $size solid transparent;
    border-top: $size solid currentColor;
    content: ' ';
  }
}

.rt-marker__content {
  display: table-cell;
  vertical-align: middle;
  white-space: nowrap;
  color: white;
}
