
.pr-tech-failures-layout-control {
	width: 700px;

	.progress-title {
		&:hover {
			cursor: auto;
		}
	}
	.time-range-radio-buttons { 
		width: 100%;
		& > * {
			display: inline-block;
			margin: 0 !important;
			padding: 0 30px 0 0;
		}
	}
	
}

.pr-tech-failures-layout-popup {
	&.ui.popup {
		padding: 0;
	}
}

.pr-tech-failures-layout-progress {
	.pbwp-value > i {
		margin: 0
	}
}