.app.audit-control.create-question-modal {
  .modal-buttons {
    display: flex;
    justify-content: flex-end;
  }

  .text-area {
    width: 100%;
    resize: none;
  }
  .ui.widget > .ui.segment {
    & > *:not(:first-child) {
      margin-top: 14px;
    }
  }
}