.attachedFile {
  position: relative;
  z-index: 1;
  cursor: pointer;
  font-weight: bold;
  padding: 10px 0 0 5px;

  span {
    font-weight: normal;
  }

  :global {
    .MuiButton-textPrimary {
      color: #1890ff;
    }
  }
}

.downloadIcon {
  color: #1890ff;

  &:hover {
    opacity: 0.6;
  }
}