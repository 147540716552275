div.leaflet-div-icon {
    text-align: center;
    vertical-align: middle;
    border-radius: 4px;
    padding: 2px 2px 0px 2px;
    font-size: small;
    margin: 0px auto;
    font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
    width: auto;
    background: '#fff';
    background-color: #f5f5f5;
    background-image: -moz-linear-gradient(top, #ffffff, #e6e6e6);
    background-image: -ms-linear-gradient(top, #ffffff, #e6e6e6);
    background-image: -webkit-gradient(linear, 0 0, 0 100%, from(#ffffff), to(#e6e6e6));
    background-image: -webkit-linear-gradient(top, #ffffff, #e6e6e6);
    background-image: -o-linear-gradient(top, #ffffff, #e6e6e6);
    background-image: linear-gradient(to bottom, #ffffff, #e6e6e6);
    background-repeat: repeat-x;
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ffffff', endColorstr='#e6e6e6', GradientType=0);
    border-color: #e6e6e6 #e6e6e6 #bfbfbf;
    border-color: rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.25);
    filter: progid:dximagetransform.microsoft.gradient(enabled=false);
    border: 1px solid #cccccc;
    border-bottom-color: #b3b3b3;
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    border-radius: 10px;
    -webkit-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.2), 0 1px 2px rgba(0, 0, 0, 0.05);
    -moz-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.2), 0 1px 2px rgba(0, 0, 0, 0.05);
    box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.2), 0 1px 2px rgba(0, 0, 0, 0.05);
}

div.leaflet-div-icon div {
    font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
    margin: 0px;
    padding: 0px;
    line-height: 12px;
}

div.leaflet-div-icon div div {
    display: block;
    font-size: xx-small;
}

div.legend-content {
    margin: 0px;
    padding: 0px;
}

div.legend-box {
    width: 10px;
    height: 10px;
    display: inline-block;
    margin-right: 4px;
    border: solid 1px #000;
    margin-bottom: 0px;
}

div.leaflet-div-icon div div.legend-box {
    width: 5px;
    height: 5px;
    max-height: 5px;
    display: inline-block;
    margin-right: 4px;
    margin-bottom: 0px;
}

div.legend-box-hidden div div.legend-box {
    display: none;
}

div.leaflet-div-icon div div.key {
    margin: 2px 4px 0px 0px;
    font-weight: bold;
    line-height: 10px;
    display: inline-block;
}

.data-layer-legend div {
    display: inline-block;
    font-size: small;
}

.choropleth-text {
    font-size: x-small;
    font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
    vertical-align: middle;
    text-align: center;
}

.scale-bars i {
    vertical-align: bottom;
    display: inline-block;
    background-color: silver;
    height: 14px;
}

.min-value, .max-value {
    vertical-align: middle;
    width: 50px;
}

.min-value {
    text-align: right;
    margin-right: 6px;
}

.max-value {
    text-align: left;
    margin-left: 6px;
}

.scale-value {
    text-align: center;
    position: relative;
}

.data-layer-legend {
    padding: 8px 0px;
}

.data-layer-legend .legend-box, .data-layer-legend .key {
    vertical-align: middle;
}

.scale-bars {
    vertical-align: middle;
    line-height: 10px;
}

.legend-title {
    font-weight: bold;
}

.leaflet-popup-content {
    overflow: auto;
    max-height: 300px;
}

.palette-element {
    display: inline-block;
    width: 12px;
    height: 14px;
}

.leaflet-control-legend {
    background-color: rgba(255, 255, 255, 0.7);
    padding: 0px;
    border-radius: 4px;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    max-height: 70px;
    width: 268px;
    box-shadow: 0 1px 7px #999;
    overflow: hidden;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    -o-user-select: none;
    user-select: none;
}

.leaflet-control-legend.larger {
    max-height: 50%;
    overflow: auto;
}

.leaflet-control-legend .legend {
    padding: 10px;
}

.leaflet-control-legend i {
    background-image: none;
    vertical-align: text-bottom;
}

.photo {
    width: 100%;
    height: 100%;
    padding: 2px;
    background-color: white;
    box-shadow: 2px 2px 3px rgba(100, 100, 100, 0.5);
    opacity: 0;
    -moz-transition: opacity 1s; /* Firefox 4 */
    -webkit-transition: opacity 1s; /* Safari and Chrome */
    -o-transition: opacity 1s;
    transition: opacity 1s;
}

img.photo {
    max-width: none;
}

.photo-info {
    background-color: rgba(0, 0, 0, 0.6);
    padding: 10px;
    position: absolute;
    top: 0px;
    color: white;
    margin: 2px;
}

.photo-link {
    float: right;
    height: 14px;
}

.author-link {
    margin-left: 2px;
    width: 400px;
    display: block;
    font-style: italic;
    font-weight: bold;
}

text.leaflet-svg-text {
    text-anchor: middle;
    alignment-baseline: central;
    dominant-baseline: central;
}