.column {
    background-color: blue;
}

.addToStyle {
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;;
}

.addTableStyle {
    border: 1px solid rgba(224, 224, 224, 1);
}