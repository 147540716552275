.card {
  position: relative;
  height: 100%;
  .cardHeader {
    color: #757575;
    font-size: 12px;
  }

  .cardThreeBody {

  }

  .DepoColumn {
    display: flex;
    flex-direction: row;
  }

  .cardVerticalBody {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .pie {
      padding: 20px 0;
    }

    .locosState {

    }
  }

  .cardHorizontBody {
    display: flex;
    flex-direction: row;
    justify-content: space-around;

    .shortInfo {
      display: flex;
      flex-direction: column;
      .bigCnt {
        color: #525252;
        font-size: 18px;
        font-weight: bold;
        padding: 10px 0px 0px;

        > span {
          color: #757575;
          font-size: 14px;
          padding-left: 20px;
          font-weight: normal;
        }
      }
      .failsCount {
        font-size: 16px;
        padding-top: 0;
        padding-bottom: 8px;
      }

      .cardField {
        .secCount {
          font-size: 18px;
          font-weight: 500;
          margin-bottom: 0;
        }
        .description {
          font-size: 14px;
        }
      }
      .thirdCard {
        display: flex;
        align-items: center;
        margin-bottom: 10px;
        p:first-child {
          font-size: 14px;
          margin-bottom: 0;
          margin-right: 10px;
        }
        p:last-child {
          font-size: 14px;
          font-weight: 500;
        }
      }
      .dayCard {
        display: flex;
        align-items: center;
        p:first-child {
          margin-bottom: 0;
          font-size: 16px;
          margin-right: 20px;
        }
        p:last-child {
          font-size: 20px;
          font-weight: 500;
        }
      }
      .target {
        display: flex;
        flex-direction: row;

        > span {
          padding-right: 3px;
        }
        p:first-child {
          font-weight: bold;
          color: rgb(246, 192, 34);
        }
        > p {
          color: #757575;
          font-size: 14px;
          padding-right: 12px;
          margin-bottom: 10px;
        }
      }

      .year {
        display: flex;
        flex-direction: row;

        > span {
          padding-right: 3px;
        }

        > p {
          color: #757575;
          font-size: 14px;
          padding-right: 12px;
        }
      }
    }

    .tinyColumn {
      padding-top: 20px;
    }
  }
}