.drugLi {
  z-index: 1000 !important;
  list-style-type: none;

  > div {
    align-items: center !important;
    display: flex !important;
    flex-direction: row !important;
    border-radius: 5px;
    border: 1px solid rgba(0, 0, 0, 0.31);
    margin-bottom: 5px;
    background: white;

    > div {
      border-radius: 3px;
      width: 10px;
      height: 10px;
      margin-left: 10px;
    }

    span {
      margin-left: 10px;
      text-transform: uppercase;
      font-family: monospace;
      line-height: 24px;
    }

  }
}