.map {
  width: 300px;
  height: 300px;
  margin: 0 auto;
  z-index: 0;
}

.title {
  width: 165px;
  color: black;
}