.pr-failure-table {
	tr {
		cursor: pointer;
	}

	&.ui.selectable.table tbody tr:hover {
		// semantic ui library have important
		background: #dff3ff !important;
	}
}


