.box {
  width: 100%;
  display: flex;
  padding: 10px 0;
  position: relative;
  &:after {
    content: "";
    cursor: pointer;
    transition: all 0.3s;
    position: absolute;
    top: 0;
    left: -10px;
    right: -10px;
    bottom: 0;
    z-index: 1;
  }

  &:hover {
    &:after {
      background-color: rgba(#000, 0.05);
    }

  }

  .avatar {
    flex: 0 0 55px;
    width: 100%;
    height: 55px;
    overflow: hidden;
    border-radius: 50%;
    position: relative;
    z-index: 2;

    .avatarImg {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }

  }

  .content {
    padding: 0 0 0 10px;
    flex: 1 1 auto;
    display: flex;
    flex-direction: column;
    justify-content: space-around;

    .head {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;

      span {
        color: #0f0f10;
        font-weight: bold;
        position: relative;
        z-index: 2;
      }

      .time {
        font-weight: normal;
        color: #6e6e6e;
      }
    }

    .body {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
      .badge{
        sup{
          display: flex;
          align-items: center;
          justify-content: center;
          width: 25px;
          line-height: normal;
          height: 25px;
          border-radius: 50%;
          background-color:#1890ff;
        }
        span{
          display: flex;
          align-items: center;
          justify-content: center;
          height: auto;
          line-height: normal;
        }
      }
      .lastMessage {
        text-overflow: ellipsis;
        width: 200px;
        white-space: nowrap;
        overflow: hidden;
        color: #1890ff;
      }

      span {
        cursor: text;
        position: relative;
        z-index: 2;
      }
    }
  }
}