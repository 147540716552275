.taskList {
  width: 100%;

  overflow: auto !important;
  flex-wrap: nowrap !important;

  .loader {
    position: absolute;
    left: 2%;
    top: 25%;
    z-index: 3;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .menuHeader {
    z-index: 2;
    position: sticky;
    top: 0;
    width: 100%;
    padding: 0px 5%;
    background-color: #f5f7f9 !important;

    .selectItem {
      padding-bottom: 6px !important;
    }

    :global {
      .active.item {
        border-color: #1890ff !important;
        color: #1890ff !important;
      }
    }

    .sortDropAria {
      font: normal normal medium 17px/20px Roboto;
      color: #6e7076;
      padding-left: 25px;
      font-weight: 500;

      .sortDrop {
        font: normal normal 300 15px/18px Roboto;

        div {
          font-weight: 400 !important;
        }
      }
    }
  }

  .emptyList {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    color: rgba(161, 161, 164, 0.74);
    font-size: 20px;
    font-weight: bold;
  }

  .taskTitle {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;

    > a {
      color: #303030;

      &:hover {
        text-decoration: underline;
      }
    }

    .statusDiv {
      padding: 1px 4px;
      border-radius: 5px;
      font-size: 12px;
      margin-left: 5px;
      color: white;
    }
  }

  a {
    .itemCnt {
      margin-left: 5px;
      padding: 3px 5px;
      border-radius: 50%;
    }
  }


  .itemRightContent {
    display: flex;
    align-items: flex-start;
    justify-content: flex-end;
    max-width: 100%;

    .itemComments {
      display: flex;
      flex-direction: row;
      align-items: center;

      > svg {
        margin-left: 5px;
      }

      .subsCnt {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        color: #a1a1a4;

        > svg {
          margin: 0 2px 0 5px;
        }
      }
    }

    > div {
      display: flex;
      flex-direction: column;
      align-items: flex-end;
      flex: 1 0 auto;
      color: rgba(0, 0, 0, 0.85);


      .labelsList {
        display: flex;
        flex-direction: row;
        margin-top: 5px;

        .labelsDiv {
          padding: 1px 4px;
          border-radius: 5px;
          font-size: 12px;
          margin-left: 5px;
          color: white;
        }

      }
    }
  }

  .loadingListItem {
    position: absolute;
    bottom: -40px;
    left: 50%;
    color: rgba(0, 0, 0, 0.64);

  }

  .dimmerAria {
    > div {
      z-index: 1;
      backdrop-filter: blur(2px);
      background-color: rgba(0, 0, 0, 0.01);
    }
  }

  .skeletonItem {
    padding: 0 5%;
  }
}