body div.progress-bar-with-popup {
	margin-bottom: 4px;
}

.progress-bar-popup {
	white-space: nowrap
}

.progress-bar-with-popup {
	border-radius: 3px;
	background-color: rgba(0,0,0,.2);
	position: relative;
	
	//border: 2px solid rgba(0,0,0,0);

	&:hover {
		box-shadow: 0 0 0 2px rgba(0, 0, 0, 0.7);
		//border: 2px solid #444;
	}

	.pbwp-progress {
		border-radius: 3px;
		position: absolute;
		top: 0;
	}

	.pbwp-traction-type {
		border-radius: 3px;
		font-size: 14px;
		font-weight: bold;
		position: absolute;
		top: 0;
		padding-left: 4px;
		margin: 0;
		text-align: left;
		white-space: nowrap;
	}

	.pbwp-clickable-traction-type {
		cursor: auto;
		&:hover {
			cursor: pointer;
			background: linear-gradient(90deg, rgba(255,255,255,.8), rgba(0,0,0,0))
		}
	}

	.pbwp-clickable-value {
		cursor: auto;
		&:hover {
			cursor: pointer;
			background: linear-gradient(90deg, rgba(0,0,0,0), rgba(255,255,255,.8))
		}
	}

	.pbwp-value {
		position: absolute;
		border-radius: 3px;
		top: 0;
		right: 0;
		margin: 0;
		padding-right: 4px;
		font-size: 16px;
		font-weight: bold;
		text-align: right;
		white-space: nowrap;
	}
}