.descAria {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  > div {
    padding-right: 30px;
  }
}