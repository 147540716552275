.downloadedRepair {
  display: flex; 
  list-style: none;
  padding-left: 0;
  overflow: scroll;

  .container {
    margin: 20px 4px;
  }

  p {
    width: 100px;
    text-align: center;
    margin: 0;
    padding: 5px 10px;
    transition: 0.4s;
  }

  :hover > p {
    background: #709ac2;
    color: #fff;
    font-weight: 600;
    cursor: pointer;
    transition: 0.4s;
  }

  .repairDateElement {
    padding: 30px;
    border: 1px solid #d7d7d7;
    text-align: center;
  
    &:hover {
      background: #709ac2; 
      color: #fff;
      font-weight: 600;
    }
  }
  
  .repairDateElement {
    border-right: 0px
  }
  
  .repairDateElement:last-child {
    border-right: 1px solid #d7d7d7 !important
  }
}

.customRepairBlock {
  display: flex;
  justify-content: space-between;

  .customRepairDate {
    display: flex;
    width: 600px;
    flex-direction: column;
    align-items: center;
  }
}

.calendPlanItem {
  margin-right: 20px
}