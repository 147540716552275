.lcm-dropdown-bold-changes {
  &.default-value.ui.dropdown > .text {
    font-weight: normal;
  }
  &.not-default-value.ui.dropdown > .text {
    font-weight: bold;
  }
  // adjust for table
  &.ui.dropdown .menu {
     //left: -40px;
  }
}