.row {
  display: flex;
  justify-content: space-between;
  &:nth-child(2) {
    margin-top: 10px;
  }
}
.cell {
  flex-basis: 100%;
  text-align: center;
  & > div {
    margin: 0 auto;
  }
}
.title {
  padding: 0 40px;
}