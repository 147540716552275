.dropdown {
  width: 420px;
  margin-right: 10px;
}

.container {
  /*text-align: right;*/
  padding-bottom: 20px;
}

.button {
  &:global(.ui.button) {
    margin-right: 0;
  }
}