.app.check-processes {
  .answer {
    border: none;
    resize: none;
    &::placeholder {
      color: rgb(191,191,191,);
      opacity: 1;
    }

  }
  .grade {
    min-width: 100px;
  }
  .reason {

  }
}