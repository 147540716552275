@import '../../css/colors';

.app.develop-recommendations {
  .table-header {
    text-align: center;
    font-size: 23px;
    color: $dark-grey;
    font-weight: normal;
  }
  .add-recommendation-button{
    margin-top: 20px;
  }
}