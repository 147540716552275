.wrap{
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 26px 0;
  .rowItem {
    margin-bottom: 12px;
    h4 {
      font: normal normal normal 12px/16px Roboto;
      line-height: 16px;
      color: #43425D;
      margin: 0 0 6px;
    }

    p {
      color: #C1C8D8;
      margin: 0;
      line-height: 14px;
      font-size: 14px;
    }
  }
}