.app.procurement-planning.main {
  .controls {
    display: flex;
    justify-content: space-between;

    .filters {
      display: flex;
      justify-content: flex-start;
      align-items: flex-end;
      > :not(:last-child) {
        margin-right: 20px;
      }
      > .button {
        margin-bottom: 1.5px;
      }
    }
    .filter-export {
      display: flex;
      justify-content: flex-end;
      align-items: flex-end;
      padding-bottom: 7px;
      > :not(:last-child) {
        margin-right: 20px;
      }
    }
  }

  .plan-button {
    margin-top: 20px;
  }
}