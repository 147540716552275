.fullBar {
  width: 4px;
  background-color: #EBEBEB;
  border-radius: 2px;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;

  .insideBar {
    width: 100%;
    border-radius: 2px;
  }
}