.rt-timebar {
  //background-color: $react-timelines-keyline-color;
  background-color: #F5F7F9;
}

.rt-timebar__row {
  position: relative;
  height: $react-timelines-header-row-height + $react-timelines-border-width;
  overflow: hidden;
  line-height: $react-timelines-header-row-height;
 // border-bottom: $react-timelines-border-width solid $react-timelines-keyline-color;
  &:last-child {
    border-bottom: $react-timelines-border-width solid $react-timelines-keyline-color;
  }
  &:first-child {
    border-top: $react-timelines-border-width solid $react-timelines-keyline-color;
  }
}

.rt-timebar__cell {
  position: absolute;
  text-align: center;
  border-bottom: none;
  background-color: $react-timelines-timebar-cell-background-color;
  color: #43425D;
  font-weight: bold;
  //border-left: 1px solid $react-timelines-keyline-color;
}
