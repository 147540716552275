.taskAriaBox {
  flex: 1 1 auto;
  overflow-y: hidden;

  .taskAria {
    flex-wrap: nowrap !important;
    width: 100% !important;
    overflow: auto !important;
    height: 100% !important;
    position: relative;

    .loadingAria {
      z-index: 3;
      position: absolute;
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: rgba(3, 3, 3, 0.1);
      color: rgba(0, 0, 0, 0.64);
      font-size: 30px;
      border-radius: 4px;

      > div {
        color: rgba(0, 0, 0, 0.64);
      }
    }

    .paperTextArea {
      height: 100%;
      display: flex;
      flex-direction: column;

      .paperHeader {
        box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
        background-color: white;
        border-radius: 10px;
        padding: 3px;
        padding-left: 10px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        flex: 0 0 auto;

        .cntDiv {
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: flex-start;

          .tasksCnt {
            padding-left: 5px;
            font-weight: 500;
            color: gray;
          }
        }

        button {
          min-width: 40px;
        }
      }

      .cardTaskStatic {
        padding: 8px;
        margin-bottom: 10px;
        background-color: white !important;
        height: 100%;
        overflow-y: auto;

        &:hover {
          cursor: pointer;
        }

        .taskHeader {
          padding: 5px;

          span {
            width: 200px;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            font-size: 14px;
            font-weight: bold;
          }

          button {
            padding: 8px;
          }
        }

        .taskContent {
          padding: 5px;

          .taskDescription {
            max-height: 63px;
            overflow: hidden;
          }

          .guestTask {
            background-color: gainsboro;
            color: black;
            border-radius: 5px;
            padding: 2px 5px;
            max-width: 36px;
            font-size: 10px;
          }

          .cardPeriod {
            padding-left: 5px;
            font-size: 12px;
            color: gray;
          }
        }
      }

      .cardTask {
        margin-bottom: 10px;
        background-color: white !important;
        height: 100%;
        overflow-y: auto;

        .addTaskCart {
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;

          .inputAddTask {
            margin-bottom: 15px;
          }

          .inputAddFullTask{
            margin: 5px 0;
          }
        }


        .taskHeader {
          padding: 5px;

          span {
            width: 200px;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            font-size: 14px;
            font-weight: bold;
          }

          button {
            padding: 8px;
          }
        }

        .taskContent {
          padding: 5px;

          .taskDescription {
            max-height: 63px;
            overflow: hidden;
          }

          .cardPeriod {
            padding-left: 5px;
            font-size: 12px;
            color: gray;
          }
        }
      }


      > div:nth-child(2) {
        flex: 1 1 auto;
        overflow-y: auto;
      }

      > div {
        //box-shadow: 0px 1px 25px #C1C8D8;
        //border-radius: 4px;
        div {
          border-radius: 6px;
        }
      }
    }
  }

}