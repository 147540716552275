$dark-blue: #405A72;
$blue: #4172A9;
$light-blue: #709ac2;

$green: #03a648;
$light-green: #78c799;
$light-green2: #b6e7cb;

$red: #e84e3e;
$light-red: #fc8b80;
$light-red2: #ffbcbc;

$orange: #ee9100;
$light-orange: #f1b353;

$black: #000;
$dark-grey: #525252;
$grey: #757575;
$light-grey: #bababa;
$light-grey2: #ebebeb;
$light-grey3: #f6f6f6;