.app.text-area-field {
  textarea {
    border: 1px solid rgba(34,36,38,.15);
    border-radius: .28571429rem;
    outline: none;
    padding: 9.5px 14px;
    resize: none;
    &::placeholder {
      opacity: 0.54
    }
    &:focus::placeholder {
      opacity: 0.8;
    }
  }
}