.gap {
  margin-bottom: 6px !important;
}
.buttonRow {
  height: 20px;
  width: 90px;
}

.disabledBarrierWithin {
  background-color: rgb(62, 207, 62);
}
.disabledBarrierExceed {
  background-color: rgb(245, 64, 50);
}