.app.order-weight-length-modal {
  .order-header {
    margin-bottom: 20px;
  }
  .order-field {
    label {
      display: block;
      margin-bottom: 4px;
      margin-top: 8px;
    }
    .allow-length-input input{
      width: 211px;
    }
  }
  .order-buttons {
    display: flex;
    justify-content: flex-end;
  }
  .attach-order-button {
    margin-top:10px;
  }
  .document {
    padding: 5px 9.5px;
  }
}