.nameSpace {
  font-weight: bold;
  font-size: 20px;
}

.divInspection {
  width: 100%;

  table {
    tbody {
      tr {
        &:hover {
          cursor: pointer;
          box-shadow: 0 3px 6px rgba(0, 0, 0, 0.32);
        }
      }
    }
  }
}

.workMark {
  border-radius: 4px;
  padding: 2px 10px;
  color: white;
  font: normal 12px/32px roboto;
}

.trainPhoto {
  border-radius: 50%;
  width: 32px;
  height: 32px;
  background-color: orange;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
}

.modalLoco {
  width: 100% !important;
  max-width: 1352px;
  min-height: 814px !important;
  top: 10% !important;

  > div {
    min-height: 814px;

    > div {
      min-height: 814px;
    }
  }
}

.modalAddProt {
  width: 100% !important;
  max-width: 676px;
  min-height: 564px;

  > div {
    min-height: 564px;

    > div {
      min-height: 564px;
    }
  }
}