.app.text-field {
  > div {
    display: flex;
    align-items: center;
    padding: 0 14px;
  }
  span {
    line-height: 19px;
  }
}
.field-width {
  width: 250px;
  padding: 1px;
  margin: 1px;
  display: block;
  text-overflow: ellipsis;
  overflow: hidden;
}
