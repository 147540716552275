.rt-timebar-key {
  height: $react-timelines-header-row-height + $react-timelines-border-width;
  padding-right: $react-timelines-sidebar-key-indent-width;
  line-height: $react-timelines-header-row-height;
  text-align: right;
  font-weight: bold;
  border: 1px solid $react-timelines-sidebar-separator-color;
  background-color: #F5F7F9;
  color: #43425D;
  font-weight: bold;

  .rt-timebar-key-with-loco {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-left: 20px;
  }

  &:last-child {
    border-bottom-color: $react-timelines-header-separator-color;
  }

  &:first-child {
    border-radius: 4px 0 0 0;
  }
}
