.road-maintenance-chart {
	
}

.road-maintenance-chart-modal {
	top: 2% !important;

	.highcharts-container {
		margin: 0 auto;
	}
	
	.modal-date {
		font-weight: normal;
		font-size: 16px;
	}
}