.dropFileClass {
  min-height: 100px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  > div {
    justify-content: center;

    > svg {
      display: none;
    }
  }
}