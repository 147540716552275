.app.add-event-modal {
  .buttons {
    display: flex;
    justify-content: space-between;
  }
  .one-button {
    display: flex;
    justify-content: flex-end;
  }
  .inputs {
    display: flex;
    justify-content: space-evenly;
    &:not(:first-child) {
      margin-top: 14px;
    }
    > div {
      flex: 1;
    }
    > div:first-child {
      padding-right: 20px;
    }
    > div:last-child {
      padding-left: 20px;
    }
  }
}