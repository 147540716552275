.taskHeaderTitle {
  .taskHeaderActions {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;

    > span {
      font-size: 24px;
      font-weight: 600;
    }

    > i {

      &:hover {
        cursor: pointer;
      }
    }

    > div {
      margin-left: 10px;

      &:hover {
        cursor: pointer;
      }
    }
  }

  .taskHeaderDesc {
    font-size: 14px;
    font-weight: 400;
  }
}