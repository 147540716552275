.segment {
  position: absolute !important;
  z-index: 1;
  padding: 5px 14px !important;
  top: 10px;
  left: 25px;
}

.emptyControl {
  height: 42px;
}