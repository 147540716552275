@import './variables';
@import './colors';

/*-redefine defaults-*/



.loadingBlock {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}

.loadingBlock div {
  display: inline-block;
  position: absolute;
  left: 8px;
  width: 16px;
  background: #1678c2;
  animation: loadingBlock 1.2s cubic-bezier(0, 0.5, 0.5, 1) infinite;
}

.loadingBlock div:nth-child(1) {
  left: 8px;
  animation-delay: -0.24s;
}

.loadingBlock div:nth-child(2) {
  left: 32px;
  animation-delay: -0.12s;
}

.loadingBlock div:nth-child(3) {
  left: 56px;
  animation-delay: 0;
}

@keyframes loadingBlock {
  0% {
    top: 8px;
    height: 64px;
  }

  50%,
  100% {
    top: 24px;
    height: 32px;
  }
}

.loadingWrap {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 50px;
}


.ui.inverted.menu,
.ui.inverted.segment {
  background-color: rgb(88, 112, 122);
}

// fix: popup ignore disabled prop
div[disabled].ui.popup {
  display: none !important;
}

.ui.page.modals {
  overflow-y: auto !important;
}

/* // Make all loader same color
.ui.dimmer .ui.loader:not(.inverted)::after {
  /*border-color: #767676 transparent transparent;
}
.ui.dimmer .ui.loader:not(.inverted)::before {
  border-color: rgba(0, 0, 0, .1);
}  */

.ui.table thead th {
  background-color: #709AC2;
  color: #ffffff;
}

.highcharts-credits {
  display: none !important;
}

.ui.form .inline.fields:first-child {
  margin-top: 15px;
}

.ui.form .field>label,
.ui.form .inline.field>label,
.ui.form .inline.fields>label {
  font-size: 14px;
  font-weight: 400;
}

.ui.structured.celled.table tr td,
.ui.structured.celled.table tr th {

  border-left: 1px solid rgba(0, 0, 0, 0.19);
  border-right: 1px solid rgba(0, 0, 0, 0.19);
  /*border-right: 1px solid rgba(34,36,38,.1);*/
}

.ui.table thead th,
.ui.celled.table tr th {
  border-bottom: 1px solid rgba(255, 255, 255, 1);
  border-right: 1px solid rgba(255, 255, 255, 1);
}

.ui.celled.table tr td {
  border: none;
}

.ui.table tr td {
  border-bottom: 1px solid rgba(0, 0, 0, 0.19);
}

.ui.table {
  border-bottom-color: rgba(0, 0, 0, 0.19);
  border-top-color: rgba(0, 0, 0, 0.19);
}

.ui.button {
  background-color: #cacbcd;
  color: rgba(0, 0, 0, .95);
}

.ui.button:hover {
  background-color: rgb(224, 225, 226);
}

.ui.menu .menu-item-collapse.item {
  padding-bottom: 0;
}

.sidebar-container .left .menu-item-collapse div.header {
  background: #49759d;
}

.ui.header {
  font-family: Calibri, sans-serif;
}

.ui.search.dropdown .menu {
  max-height: 33.3714rem;
}

//----mine----*/
.sidebar-container {
  min-height: calc(100vh - 80px);
}

.filters-sidebar {
  padding: 15px 20px;
}

.filters-sidebar .form {
  padding-bottom: 20px;
}

.report-container {
  padding: 0 !important;
  // padding: 0 0 0 10px !important;
  // width: 100%;
  //max-height: calc(100vh - #{$topBarHeight});
  // overflow-x: auto;

  font-family: Calibri, sans-serif;
}

.canvas {
  width: 100%;
  height: calc(100vh - #{$topBarAndRoutePaddingHeight});
  z-index: 0;
}

.ui.segment.not-report-container {
  /* to prevent scrolls on map*/
  padding: 0px;
  overflow: auto;
}

.report-container th,
.report-container td {
  padding: 5px !important;
}

.report-container table {
  font-size: 12px !important;
}

.sidebar-container {
  border: 0px !important;
  border-radius: 0 !important;
}

.sidebar-container .ui.basic.segment.report-container {
  background-color: rgb(88, 112, 122);
}

.sidebar-container .ui.vertical.menu .menu .item {
  padding-bottom: 4px;
}

.sidebar-container .ui.vertical.menu .item .menu .item {
  font-weight: normal;
  font-size: 14px;
}

.sidebar-container .ui.vertical.menu .item .menu .active.item:hover {
  text-decoration: underline;
}

.sidebar-container .ui.vertical.menu .item .menu .disabled.item {
  color: rgba(255, 255, 255, 0.5) !important;
}

.sidebar-container .left div.header {
  color: rgba(255, 255, 255, 1) !important;
  padding: 13px !important;
  margin: -13px -15px 0 -15px !important;
  font-size: 16px !important;
  background-color: rgb(74, 98, 108);
}

.sidebar-container .ui.vertical.menu div.item {
  border-top: 1px solid rgb(113, 135, 144);
}

.filters-sidebar.menu .ui.inverted.form input[type="text"],
.filters-sidebar.menu div.dropdown,
.filters-sidebar.menu button {
  border: 1px solid rgb(74, 98, 108);
}

@media all and (max-width: 1080px) {
  .sld-loading.ui.large.modal {
    width: calc(100vw - 55px);
  }

  .sld-loading.ui.large.modal .content {
    padding: 5px;
  }
}

.ditches-grid {
  overflow-y: auto;
  max-height: 500px;
}

.ditches-grid.ui.grid>.row {
  padding: 4px 0;
}

.ditches-grid.ui.grid p {
  margin: 0;
}

.sld-loading .ui.grid>.red.column,
.ui.grid>.red.row,
.ui.grid>.row>.red.column {
  background-color: rgb(235, 61, 61) !important;
  margin: 0 1px;
  border-radius: 5px;
  padding: 3px;
}

.sld-loading .ui.grid>.red.column,
.ui.grid>.red.row,
.ui.grid>.row>.green.column {
  background-color: rgb(40, 168, 67) !important;
  margin: 0 2px;
  border-radius: 5px;
  padding: 3px;
}

.sld-loading .ui.grid>.red.column,
.ui.grid>.red.row,
.ui.grid>.row>.yellow.column {
  background-color: rgb(214, 181, 45) !important;
  margin: 0 2px;
  border-radius: 5px;
  padding: 3px;
}

.sld-loading .ui.grid>.red.column,
.ui.grid>.red.row,
.ui.grid>.row>.grey.column {
  background-color: rgb(192, 193, 195) !important;
  margin: 0 2px;
  border-radius: 5px;
  padding: 3px;
}

.sld-loading .ui.grid>.red.column,
.ui.grid>.red.row,
.ui.grid>.row>.closed.column {
  margin: 0 2px;
  padding: 3px;
}


.sld-loading .ui.grid>.red.column,
.ui.grid>.red.row,
.ui.grid>.row>.empty.column {
  margin: 0 2px;
  border-radius: 5px;
  padding: 3px;
}


.news-item.visible.transition.ui.modal .scrolling.content {
  max-height: calc(90vh);
  overflow: auto;
}

.login-form .icon {
  color: rgb(157, 172, 178);
}

.ui.button.login {
  background-color: rgb(157, 172, 178);
  color: white;
  text-shadow: 1px 1px 1px rgb(69, 80, 91);
}

// Title Bar
.ui.top.menu {
  position: fixed;
  z-index: 100;
  top: 0;
  width: 100%;
  margin: 0;

  .item {
    padding: 5px 10px;
  }
}


.route-container {
  padding: $routePadding  !important;
  height: calc(100vh - #{$topBarHeight});
  overflow-y: auto;
  /*display: flex ;*/
}

// Content container
.ui.segment.pushable.sidebar-container {
  padding-top: $topBarHeight  !important;
  height: 100vh !important;
  margin: 0;
  overflow-y: hidden;
  top: 0;
}

// Menu
.ui.sidebar.menu {
  position: fixed;
  padding-top: $topBarHeight;
  height: 100vh !important;
  z-index: 1050;

  .header {
    cursor: pointer;
  }
}

.rerun-warning {
  background-color: rgb(238, 223, 160);
}

.rerun-negative {
  background-color: rgb(240, 154, 154);
}

.loading-report {
  height: 1000px;
}

/* data-percent order is important*/
.sld-load.ui.progress .bar,
.sld-load.ui.indicating.progress[data-percent^="1"] .bar,
.sld-load.ui.indicating.progress[data-percent^="2"] .bar,
.sld-load.ui.indicating.progress[data-percent^="3"] .bar,
.sld-load.ui.indicating.progress[data-percent^="4"] .bar,
.sld-load.ui.indicating.progress[data-percent^="5"] .bar {
  background-color: #66DA81;
  min-width: 0.5em;
}

/*.sld-load.ui.indicating.progress[data-percent^="3"] .bar {
    background-color: #B4D95C;
}

.sld-load.ui.indicating.progress[data-percent^="4"] .bar,
.sld-load.ui.indicating.progress[data-percent^="5"] .bar {
    background-color: #DDC928;
}*/

.sld-load.ui.indicating.progress[data-percent^="6"] .bar,
.sld-load.ui.indicating.progress[data-percent^="7"] .bar,
.sld-load.ui.indicating.progress[data-percent^="8"] .bar {
  background-color: #E6BB48;
  /*background-color: #EFBC72;*/
}

.sld-load.ui.indicating.progress[data-percent^="9"] .bar,
.sld-load.ui.indicating.progress[data-percent^="100"] .bar {
  background-color: #D95C5C;
}

.sld-load.ui.indicating.progress[data-percent="1"] .bar,
.sld-load.ui.indicating.progress[data-percent="2"] .bar,
.sld-load.ui.indicating.progress[data-percent="3"] .bar,
.sld-load.ui.indicating.progress[data-percent="4"] .bar,
.sld-load.ui.indicating.progress[data-percent="5"] .bar,
.sld-load.ui.indicating.progress[data-percent="6"] .bar,
.sld-load.ui.indicating.progress[data-percent="7"] .bar,
.sld-load.ui.indicating.progress[data-percent="8"] .bar,
.sld-load.ui.indicating.progress[data-percent="9"] .bar {
  background-color: #66DA81;
}

.root-pred {
  opacity: 1 !important;

  span {
    font-weight: bold;
  }
}

.child-pred-1 span {
  padding-left: 25px;
}

.child-pred-2 span {
  padding-left: 50px;
}

.child-pred-3 span {
  padding-left: 75px;
}

.depo-href {
  text-decoration: underline;
}

.depo-href:hover {
  cursor: pointer;
}

.table-filter:hover {
  cursor: pointer;
}

.stall-info.ui.popup>.header {
  font-family: Calibri, sans-serif;
}

/* table header always on the top (during scroll) */
/*.tpe-popup .ui.table thead tr:first-child > th {
    position: sticky !important;
    top: 0;
    z-index: 2;
}*/
/* 
.tpe-popup .ui.table {
  width: 1400px !important;
  overflow-y : auto;
} */

.tpe-popup .ui.table td {
  cursor: pointer;
  padding-top: 2px;
  padding-bottom: 2px;
}

.ui.tabular.menu .active.item.rerun-warning {
  background-color: rgb(238, 223, 160);
}

.ui.tabular.menu .active.item.rerun-negative {
  background-color: rgb(240, 154, 154);
}

.visible.transition.tpe-details {
  margin-right: -33vw;
}


@media all and (max-width: 1080px) {
  .tpe-details.ui.large.modal {
    width: calc(100vw - 55px);
  }

  .tpe-details.ui.large.modal .content {
    padding: 5px;
  }
}

.ui.modal.tpe-details img {
  width: 100%;

  //object-fit: contain;
  //width: 275px;
  //height: 275px;
}

.ui.grid.section-base-info .row {
  padding: 5px 0;
}

.action-buttons-item .right.floated.content {
  visibility: hidden;
}

.action-buttons-item:hover .right.floated.content {
  visibility: visible;
}

.management.ui.segment {
  padding: 0;
}

.roles-management.ui.text.menu {
  background-color: rgb(242, 230, 230);
  margin: 0;
  padding: 14px;
}

.management.ui.segment .ui.list {
  margin: 14px;
}

.management.ui.segment .ui.list.functions {
  height: calc(100vh - 200px);
  overflow: auto;
}

.management.ui.segment.roles-parent {
  height: calc(50vh - 200px);
}

.management.ui.segment .ui.list.roles {
  height: calc(50vh - 160px);
  overflow: auto;
}

.management.ui.segment .ui.list.role-functions {
  height: calc(50vh - 160px);
  overflow: auto;
}

.runs.ui.table thead th,
.runs.ui.table td,
.routes.ui.table thead th,
.routes.ui.table td,
.loc-states.ui.table thead th,
.loc-states.ui.table td,
.triggered-barrier-functions.ui.table thead th,
.triggered-barrier-functions.ui.table td {
  padding: 5px;
}

.runs.ui.table,
.routes.ui.table,
.loc-states.ui.table {
  font-size: 12px;
}

.tpe-details.visible.transition,
.add-schedule.visible.transition {
  top: 25px;
}

.ui.modal.tpe-details,
.ui.modal.tpe-details .ui.menu {
  font-family: Calibri, sans-serif;
}

.ui.modal.tpe-details .scrolling.content {
  max-height: calc(95vh);
}

.tpe-details-additional.ui.accordion .title:not(.ui) {
  border-top: 1px solid rgb(212, 212, 213);
  padding: 12px 0;
}

.tpe-details-additional.ui.accordion:not(.styled) .title~.content:not(.ui) {
  padding-top: 0;
}

.ui.selection.list>.item.action-buttons-item.selected {
  background-color: rgb(247, 247, 247);
}

.role-preds.ui.search.dropdown .menu {
  max-height: 24.3714rem;
}

.big-image {
  z-index: 999;
  position: absolute;
  /*for chrome*/
}

.el-pass-content {
  height: 15vw;
  overflow-y: auto;
}

.el-pass.ui.table thead th,
.el-pass.ui.table td {
  padding: 5px;
}

.el-pass.ui.table {
  font-size: 12px;
  font-family: Calibri, sans-serif;
}

.ep-first-lvl {
  font-weight: bold;
}

.repair-href {
  text-decoration: underline;
}

.repair-href:hover {
  cursor: pointer;
}

.add-role.visible.transition {
  top: 25px;
}

.add-role .scrolling.content {
  height: 500px;
}

.edit-role.visible.transition {
  top: 25px;
}

.edit-role .scrolling.content {
  height: 500px;
}

.ui.grid.diagnostic-service-data .row {
  padding: 5px 0;
}

.ui.grid.diagnostic-service-data .ui.red.label {
  background-color: rgb(240, 154, 154) !important;
  color: black !important;
}

.ui.grid.diagnostic-service-data .ui.green.label {
  background-color: #66DA81 !important;
  color: black !important;
}

.ui.grid.diagnostic-service-data .ui.yellow.label {
  background-color: rgb(238, 223, 160) !important;
  color: black !important;
}

.employee-repairs.visible.transition {
  top: 25px;
}

.tpe-name-label {
  width: 180px !important;
}

.checklist-event.ui.segment {
  padding: 0.5em;
  margin-bottom: 1.5rem;
}

.depot-docs.ui.popup .header {
  width: 450px;
}

.depot-docs.ui.popup .header+.content {
  padding: 0.5em 0;
}

.section-event {
  font-family: Calibri, sans-serif;
  cursor: pointer;
}

.ui.table tr.section-event td {
  border-bottom: 0px;
}

.ui.table.notifications-list {
  height: calc(50vh);
}

.ui.table.notifications-list tbody {
  height: calc(50vh) !important;
}

.clickable {
  cursor: pointer;
}

/* table header always on the top (during scroll) */
.sections-list .ui.table thead tr:first-child>th {
  position: sticky !important;
  top: 0;
  z-index: 2;
}

.sections-list {
  height: 33vh;
  overflow-y: auto;
}

.mileage-warning-info,
.mileage-warning-info:last-child {
  margin-bottom: 5px;
}

.mileage-warning-info.restrict {
  color: red;
}

.leaflet-control-attribution a>svg {
  display: none !important;
}
