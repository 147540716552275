@import '../../css/colors';
.component {
  position: relative;

  :global {
    .control-buttons {
      display: flex;
      justify-content: space-between;
      align-items: flex-end;
    }
    .steps-container {
      display: flex;
      align-items: center;
      flex-direction: column;
      padding-top: 20px;
      overflow: hidden;
      padding-bottom: 20px;
    }
    .steps-title {
      text-align: center;
      font-size: 24px;
      font-weight: normal;
    }
    .close-button {
      display: flex;
      justify-content: center;
      align-items: center;
      position: absolute;
      z-index: 1;
      padding: 19px;
      margin: 0;
      top: 0;
      right: 0px;
      cursor: pointer;
      font-size: 18px;
      color: $light-grey;
      &:hover {
        color: $grey;
      }
    }
  }
}