.dropzone{
  min-height: auto!important;
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
  justify-content: center;
  >div{
    display: flex;
    align-items: center;
    justify-content: center;
    svg{
      margin: 0 10px;
      width: 25px;
      height: 25px;
    }
  }
  p{
    font-size: 12px;
  }
}