.commentTitle {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;

  :global {
    .MuiSvgIcon-colorPrimary {
      color: #1890ff !important;
    }
  }
}

.skeletonComment {
  display: flex;
  align-items: center;

  > div:last-child {
    margin-left: 20px;
    flex: 1 1 auto;
  }
}

.head {
  display: flex;
  align-items: center;

  svg {
    width: 30px;
    height: 30px;
    margin-right: 10px;
  }
}

.mainComment {
  width: 100%;
  display: flex;
  align-items: center;

}
