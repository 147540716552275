.loadingSegment {
  width: 100px;
  height: 100px;

}
.numberOfTPS {
  box-shadow: 0 1px 5px rgba(0, 0,0, 40%);
  background: #fff;
  border-radius: 5px;
  padding: 2px 5px;
}