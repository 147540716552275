@import '../../css/colors';

.component {
  @function outlineShadow($color) {
    @return inset 0px 0px 0px 2px $color;
  }

  margin-bottom: 15px;

  :global {
    .audit {
      cursor: pointer;
      display: flex;
      align-items: center;
      padding-top: 6px;
      padding-bottom: 6px;
    }
    .circle {
      width: 18px;
      height: 18px;
      border-radius: 50%;
      margin-right: 16px;
    }
    .circle-fill-red {
      background-color: $light-red;
    }
    .circle-fill-green {
      background-color: $light-green;
    }
    .circle-fill-orange {
      background-color: $light-orange;
    }
    .circle-fill-blue {
      background-color: $light-blue;
    }
    .circle-fill-grey {
      background-color: $grey;
    }
    .circle-outline-red {
      box-shadow: outlineShadow($light-red);
    }
    .circle-outline-green {
      box-shadow: outlineShadow($light-green);
    }
    .circle-outline-orange {
      box-shadow: outlineShadow($light-orange);
    }
    .circle-outline-blue {
      box-shadow: outlineShadow($light-blue);
    }
    .circle-outline-grey {
      box-shadow: outlineShadow($grey);
    }
    .audit-text {
      font-size: 12px;

      &:not(.active) {
        color: $light-grey;
      }
    }
  }
}

/*
  @function dayOutline($color) {
    @return inset 0px 0px 0px 3px $color;
  }

    // Day options
    .day-fill-red {
      background-color: $light-red;
    }
    .day-fill-green {
      background-color: $light-green;
    }
    .day-fill-orange {
      background-color: $light-orange;
    }
    .day-fill-blue {
      background-color: $light-blue;
    }
    .day-outline-red {
      box-shadow: dayOutline($light-red);
    }
    .day-outline-green {
      box-shadow: dayOutline($light-green);
    }
    .day-outline-orange {
      box-shadow: dayOutline($light-orange);
    }
    .day-outline-blue {
      box-shadow: dayOutline($light-blue);
    }

*/