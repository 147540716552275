.app.time-field {
  input {
    border: 1px solid rgba(34,36,38,.15);
    border-radius: .28571429rem;
    outline: none;
    height: 38px;
    padding-left: 14px;
    &:focus {
      border-color: #85b7d9;
    }
  }
}