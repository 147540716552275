.userSelectedList {
  overflow-y: auto;

  .listActions {
    display: flex;
    flex-direction: row;
    align-items: center;

    .userRole {
      color: grey;
      user-select: none;
    }

    :first-child {
      margin-right: 10px;
    }
  }
}