.grid {
  :global(.row) {
    padding: 5px 0 !important;
  }

  :global(.header) {
    font-weight: normal !important;
  }

  :global(.ui.red.label) {
    background-color: rgb(240, 154, 154) !important;
    color: black !important;
  }

  :global(.ui.green.label) {
    background-color: #66DA81 !important;
    color: black !important;
  }

  :global(.ui.yellow.label) {
    background-color: rgb(238, 223, 160) !important;
    color: black !important;
  }
}